/**
 * Configuration for user-interface
 */
import {defineStore, storeToRefs} from "pinia";
import {useMapStore} from "@/store/map-store";
import {searchStation} from "@/lib/api";
import {useLocationsStore} from "@/store/locations-store";
//import {fitLocationsToBounds} from "@/lib/map";
//import {useLocationsStore} from "@/store/locations-store";

export const useUiStore = defineStore('ui',{
    state: () => ({
        /** Site is in maintenance mode **/

        maintenance: {
            enabled: false,
            allow_password: false,
            password: '123456'
        },

        deviceType: 'desktop',



        showDemoNotice: true,


        /**
         *
         *  - Control maintenance mode, allow password access toggle
         *  - Hold a sha512 hash of the password to access
         *
         *  - Control what features are enabled
         *
         */

        features: {

        },


        /**
         * Search
         */
        search: {
            debounceTimerId: null,
            userHasSearched: false,
            showSearchResults: false,   // controls if search results or locations are shown
            expanded: false,
            searchQuery: '',
            searchResults: [],
            selectedLocation: null
        },


        /** Filters **/

        filters: {
            open: false
        },


        /**
         * Sidebar
         */

        sidebar: {
            open: false
        },

        userLocation: {
            showPin: false
        },

        directionSearch: {
            open: false
        },


        /** Controls if map component is loaded */
        loadMap: false,

        /** Controls the state of map container loading */
        loadMapContainer: false,

        /** Has the mapbox instance been created */
        mapCreated: false,

        /** Controls the state of the dialog component **/
        dialog: {
            show: false,
            text: null,
            title: null,
            persistent: false,
            acceptText: 'Samþykki',
            denyText: 'Hafna',
            callback: {
                accept: null,
                deny: null
            },
            styling: {
                color: '#ff0000',
                backgroundColor: '#ff123f'
            }
        },

        messageBox: {
            show: false,
            text: null,
            timeout: 5000
        }


    }),
    actions: {
        /** dialog actions **/
        showConfirmDialog(title, text, acceptCallback, denyCallback, options = {}) {
            this.dialog.show = true
            this.dialog.title = title
            this.dialog.text = text
            this.dialog.callback.accept = acceptCallback
            this.dialog.callback.deny = denyCallback
            this.dialog.acceptText = options.acceptText || 'Samþykki'
            this.dialog.denyText = options.denyText || 'Hafna'
            this.dialog.persistent = options.persistent || false
            this.dialog.styling = options.styling || {
                color: '#ff0000',
                backgroundColor: '#ff123f'
            }
        },

        resetConfirmDialog() {
            this.dialog.show = false
            this.dialog.title = null
            this.dialog.text = null
            this.dialog.callback.accept = null
            this.dialog.callback.deny = null
            this.dialog.persistent = false
            this.dialog.styling = {
                color: '#ff0000',
                backgroundColor: '#ff123f'
            }
        },
        /** end of dialog actions **/

        /** message box actions **/
        showMessageBox(text, timeout = 5000) {
            this.resetMessageBox()
            this.messageBox.show = true
            this.messageBox.text = text
            this.messageBox.timeout = timeout
        },

        resetMessageBox() {
            this.messageBox.show = false
            this.messageBox.text = null
            this.messageBox.timeout = 5000
        },

        /** end of message box actions **/

        showUserLocationPin() {
            this.userLocation.showPin = true
        },
        hideUserLocationPin() {
            this.userLocation.showPin = false
        },
        setDirectionSearchOpen() {
            this.setSidebarOpen()
            this.directionSearch.open = true
        },
        setDirectionSearchClosed() {
            this.setSidebarClosed()
            this.directionSearch.open = false
        },
        setSidebarOpen() {
            console.log('opening sidebar')
            this.sidebar.open = true
        },
        setSidebarClosed() {
          this.sidebar.open = false
        },
        setDeviceType(deviceType) {
            /** Setting device type in ui-store */
            console.log('setting device type in ui-store', deviceType)

            this.deviceType = deviceType
        },
        setDeviceTypeFromWidthHeight(width, height) {
            // first check if is mobile
            let isMobile = Math.min(width, height) < 768 || navigator.userAgent.indexOf("Mobi") > -1;
            if (isMobile) {
                // check if is tablet
                let isTablet = Math.min(width, height) > 768;
                if (isTablet) {
                    this.setDeviceType('mobile')
                } else {
                    this.setDeviceType('mobile')
                }
            } else {
                // is desktop
                this.setDeviceType('desktop')
            }
        },
        showMap() {
            console.log('showMap in ui-store was called')
            this.loadMap = true
        },
        hideMap() {
            this.loadMap = false
            this.mapCreated = false
        },
        openFilters() {
            this.filters.open = true
        },
        closeFilters() {
            this.filters.open = false
        },
        toggleFilters() {
            this.filters.open = !this.filters.open
        },
        setMapCreated() {
            console.log('setting map created in ui-store')
            this.mapCreated = true
        },
        hideSearchResults() {
            this.search.showSearchResults = false
        },
        showSearchResults() {
            this.search.showSearchResults = true
        },
        resetSearch() {
            /** controls the toggle between filtered locations and search results */
            this.search.showSearchResults = false

            this.collapseSearch()


            //this.search.expanded = false
            this.search.searchQuery = ''
            this.search.userHasSearched = false
            this.search.searchResults = []



            //this.setSidebarClosed()

            setTimeout(() => {
                const { mapboxInstance } = storeToRefs(useMapStore())

                mapboxInstance.resize() // resize map to fit new sidebar size

            }, 100)

        },
        handleSearchQueryChangeEvent(event) {
            this.setSearchQuery(event.target.value)
            this.fetchSearchResults()
            console.log('search query changed', event.target.value)
        },
        setSearchQuery(query) {
            this.search.searchQuery = query
        },
        setSearchResults(results) {
            this.search.searchResults = results
            //this.search.showSearchResults = true
            //this.search.userHasSearched = true    // this controls the sidebar
        },
        expandSearch() {
            this.search.expanded = true
        },
        collapseSearch() {
            this.setSearchResults([])
            this.search.expanded = false
            this.search.searchQuery = ""
            this.search.showSearchResults = false

            const { resetSelectedLocation } = useLocationsStore()
            resetSelectedLocation()

            const { resetMapCamera } = useMapStore()
            resetMapCamera()

            this.$forceUpdate()

        },
        /**
         * This is a debounced function that fetches search results
         * @param query
         */
        fetchSearchResults() {
            if (!this.search.searchQuery) {
                //this.resetSearch()
                return
            }

            /** reset pending search call */
            clearTimeout(this.search.debounceTimerId)

            this.search.debounceTimerId = setTimeout(() => {
                /** Fetch the search results **/
                searchStation(this.search.searchQuery).then((data) => {

                    console.log('Search response: ', data)
                    //this.searching = false
                    //this.stations = Object.values(data)

                    this.search.searchResults = Object.values(data)

                    console.log('type of data: ', typeof(Object.values(data)))


                    //console.log('DATA:: ', data)

                    /**
                     * We need to fit the map to the bounds of the search results
                     */
                    const locations = Object.values(data)

                    this.setSearchResults(locations)

                    /** If there are more than 1 locations, calculate the center **/
                    if (locations.length > 1) {

/*                        const { mapboxInstance } = storeToRefs(useMapStore())

                        setTimeout(() => {
                            fitLocationsToBounds(mapboxInstance, locations)
                        }, 200)*/

                        this.showSearchResults()

                        /** Open the sidebar */
                        //this.setSidebarOpen()
                        //this.mapboxInstance.resize()  // let mapbox know that the sidebar is open

                        return
                    }



/*                    /!** If there is only location in result, fly to location and open infobox **!/
                    if (locations.length === 1) {
                        let location = locations[0]
                        this.mapboxInstance.flyTo({
                            center: location.geometry.coordinates,
                            zoom: 15
                        })
                        this.store.sidebarOpen = true

                        const { mapboxInstance } = storeToRefs(useMapStore())

                        mapboxInstance.resize()  // let mapbox know that the sidebar is open

                        const { setSelectedLocation } = useLocationsStore()


                        setSelectedLocation(location)

                        return
                    }*/

                    /** If there are no locations, show a message to the user **/
                    if (locations.length === 0) {
                        console.log('No locations found')
                        return
                    }


                }).catch((error) => {
                    console.log('Search error: ', error)
                })


            }, 500)
        }
    },
    getters: {
        getShowUserLocationPin: (state) => state.userLocation.showPin,
        getUserHasSearched: (state) => state.search.userHasSearched,
        getSidebarOpen: (state) => state.sidebar.open,
        getDirectionSearchOpen: (state) => state.directionSearch.open,
        getDeviceType: (state) => state.deviceType,
        getMapShouldLoad: (state) => state.loadMap,
        getMapVisibility: (state) => state.loadMap && state.mapCreated,
        getSearchResults: (state) => state.search.searchResults,
        getSearchQuery: (state) => state.search.searchQuery,
        getSearchExpanded: (state) => state.search.expanded,
        getShowSearchResults: (state) => state.search.showSearchResults,
    }

})