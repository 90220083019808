/***
 * This is the driving store
 *
 *  - Holds values to help calculate estimated location
 *  - Holds current KM/h
 *  -
 */
import {defineStore} from "pinia";

export const useDrivingStore = defineStore('driving-mode', {
    state: () => ({
        legs: []
    }),
    getters: {

    },
    actions: {
        setLegs(legs) {
            this.legs = legs
        }
    }
})