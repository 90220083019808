<template>
  <div id="sidebar" :class="{ 'mobile': getDeviceType === 'mobile' | getDeviceType === 'tablet', 'infobox-open': getSidebarOpen, }" style="z-index: 2000;" class="hidden-md-and-down bg-dark sidebar col-lg-3 col-sm-12 col-md-12">
    <div v-if="station && getDeviceType !== 'mobile'" class="list-group list-group-flush border-bottom scrollarea">

      <!-- bootstrap header -->
      <a
          class="list-group-item list-group-item-action active py-3 lh-sm"
          aria-current="true"
          :style="{
            'background-color': settings.cardHeaderBackgroundColor,
            'color': settings.cardHeaderTextColor,
            'font-size': '1.3rem'
          }"
      >
        <div class="d-flex w-100 align-items-center justify-content-between">


          <NetworkInfo :station="station" />

          <strong class="mb-1 text-center">
            <img height="30" width="30" :src="station.properties.pin" class="d-none" alt="">
            <span class="p-2">{{ station.properties.name }} <a v-if="settings.showEditLocationButtonInCard" class="btn btn-info p-2" :href="'https://hlada.fotbolti.is/admin/locations/' + station.properties.id + '/edit'">Edit</a> </span>
          </strong>


          <b-button variant="danger" size="sm" @click="resetSelectedLocation">
            <b-icon icon="info-circle">x</b-icon>
          </b-button>
        </div>

      </a>

      <div class="scroller">
        <div class="text-white p-4">
          <strong>
            {{ station.properties.brand_name }}
          </strong>
        </div>
        <div class="location-info text-white p-2">
          <p>{{ station.properties.address }}</p>
          <p>{{ station.properties.city.city }}, {{ station.properties.city.postalCode }}</p>
          <p>{{ station.properties.city.type }}, {{ station.properties.city.region }}</p>
        </div>
        <ConnectionType dark />
        <PaymentType :payment-provider-key="station.properties.brand_key"/>
      </div>

    </div>

    <direction-search v-if="getDirectionSearchOpen"></direction-search>

<!--    <search-result-box v-if="getShowSearchResults"></search-result-box>-->

    <div class="scroller d-none">
      <trip-information></trip-information>
    </div>


  </div>
</template>

<script>
import {useStore} from "@/main";
//import {getAnalytics, logEvent} from "firebase/analytics";
//import {fitLocationsToBounds} from "@/lib/map";
//import {toRaw} from "vue";
import ConnectionType from "@/components/ConnectionType";
import NetworkInfo from "@/components/NetworkInfo";
import PaymentType from "@/components/PaymentProvider";
import DirectionSearch from "@/components/DirectionSearch";
import {useSettingsStore} from "@/store/settings-store";
//import {useMapStore} from "@/store/map-store";
import {useUiStore} from "@/store/ui-store";
import {storeToRefs} from "pinia";
import {useLocationsStore} from "@/store/locations-store";
import {useMapStore} from "@/store/map-store";
import TripInformation from "@/components/ui/TripInformation";

export default {
  name: "StationInfo",
  components: {TripInformation, DirectionSearch, PaymentType, NetworkInfo, ConnectionType},
  created() {
    this.store = useStore()
  },
  setup() {

    const {
      getDeviceType,
      getSidebarOpen,
      getDirectionSearchOpen,
      getShowSearchResults,
      getUserHasSearched
    } = storeToRefs(useUiStore())

    const { frontendSettings } = storeToRefs(useSettingsStore())

    const { setSidebarClosed, showSearchResults } = useUiStore()

    const { selectedLocation, locations } = storeToRefs(useLocationsStore())

    const { setSelectedLocation, resetSelectedLocation } = useLocationsStore()

    const { stopCameraRotation, informResize, resetMapCamera } = useMapStore()

    const { mapboxInstance } = storeToRefs(useMapStore())

    return {
      settings: frontendSettings,
      station: selectedLocation,
      stations: locations,
      getDeviceType,
      getSidebarOpen,
      setSidebarClosed,
      stopCameraRotation,
      getDirectionSearchOpen,
      informResize,
      getShowSearchResults,
      setSelectedLocation,
      mapboxInstance,
      getUserHasSearched,
      showSearchResults,
      resetSelectedLocation,
      resetMapCamera
    }
  },
  methods: {

  },

  watch: {
    open() {
      console.log('Sidebar is ', this.open ? 'open' : 'closed')
      /** let mapbox know the resize event */
      this.mapboxInstance.resize()
    }
  },
}
</script>

<style scoped>
  #sidebar.mobile {
    overflow: auto;
  }

  #sidebar {
    transform: translateX(-100%);
    transition: transform 0.7s ease-in-out;
  }

  #sidebar.infobox-open {
    transform: translateX(0);
    transition: transform 0.7s ease-in-out;
  }

  .mobile {
    top: 60vh !important;
    height: 40vh !important;
    width: 100vw !important;
  }

  .sidebar {
    top: 6.3vh;
    display: block;
    position: fixed;
    height: 93.7vh;
  }
</style>