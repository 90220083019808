<template>
  <div :class="{'bg-white' : !dark }">
    <h4 class="sub-heading">Payment Information</h4>
    <div class="col-12 payment-type-list">
      <div class="row">
        <div class="col-12 pb-4">
          <span>To pay at this charging station you must download the N1 app.</span>
        </div>
        <div class="col-6">
          <img src="/misc/n1-app.jpeg" alt="">

        </div>
        <div class="col-6">
          <img src="/misc/appstore.png" alt="">
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "@/main";

export default {
  name: "PaymentProvider",
  props: {
    paymentProviderKey: String,
    dark: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.store = useStore()
  },
  computed: {
    paymentProvider() {
      return this.store.paymentProviders.find((paymentProvider) => {
        return paymentProvider.key == this.paymentProviderKey
      })
    },
    paymentProviders() {
      return this.store.paymentProviders
    },
  },
}
</script>

<style scoped>

  .on-logo {
    filter: invert(0.3) sepia(2) saturate(7) hue-rotate(320deg);

  }

  .app-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }

  div > img {
    width: 100%;
  }

  .payment-type-list {
    padding: 1rem;
    display: flex;
  }

  .plug > span {
    font-size: 0.6rem;
    font-weight: 600;
    color: rgb(15,245,25)
  }

  h4.sub-heading {
    padding: 1rem;
    padding-bottom: 0.1rem;
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
</style>