import { createApp } from 'vue'
import App from './App.vue'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import { aliases, mdi } from 'vuetify/iconsets/mdi'

const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi
        }
    }
})

// as an es module import:
const RayPlugin = require('vue-ray').default;

import {BootstrapVue3, BToastPlugin} from 'bootstrap-vue-3'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import {createPinia} from "pinia";

// Define the store
import { defineStore } from 'pinia'
// import vue router
import { createWebHashHistory, createRouter } from 'vue-router'


/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faMagnifyingGlass, faPenAlt } from '@fortawesome/free-solid-svg-icons'



library.add(faMagnifyingGlass)
library.add(faPenAlt)

const app = createApp(App)

app.use(vuetify)


const pinia = createPinia()



app.use(BToastPlugin)

app.use(BootstrapVue3)

app.use(pinia)

/** Register Ray Plugin **/
app.use(RayPlugin, {
    interceptErrors: true,
    //showComponentEvents: ['created', 'mounted'],
    nodeRaySettings: {
        interceptConsoleLog: true,
    },
});


export const useStore = defineStore('main', {
    state: () => ({
        count: 0,
        deviceType: 'desktop',
        currentPosition: [-19.9510378, 65.0358811],
        currentZoom: 4.9,
        mapPitch: 20,

        // driving mode
        drivingMode: false,

        userLocationEnabled: false,

        // user location consent
        userLocationConsent: false,

        userLocationWatchId: null,

        userBearing: 0,

        lastUserLocations: [],

        userBearingCounter: 0,

        userLocationTimeList: [],

        userLocationLastPositionReceivedAt: 0,

        directionSearch: false,

        // user location
        userLocation: [-18.87603644549995, 64.10174435366892],

        // controls the state of the searchbox
        searchExpanded: false,
        searchFiltersExpanded: false,

        // search settings
        userHasSearched: false,

        // stations
        stations: [],

        // search results
        searchResults: [],

        // Api Key Mapbox
        mapboxApiKey: '',

        // Mapbox instance
        mapboxInstance: null,

        // InfoboxId
        openInfoboxId: null,

        // Toggle if sidebar is open or not
        sidebarOpen: false,

        activeStation: null,

        mapboxStyleUrl: '',

        maxBounds: [
            [-31.0571422, 62.574948], // Southwest coordinates
            [-7.382389, 67.603875] // Northeast coordinates
        ],

        mapZoomLevel: 49,

        networks: [],

        plugTypes: [],

        /** Active filters */
        filters: {
            networks: [],
            plugTypes: []
        },

        // define the starting position of the map for each device type
        startPosition: {
            mobile: {
                x: -18.70257964318,
                y: 64.457239730444705,
                zoom: 4.912
            },
            tablet: {
                x: -18.9510378,
                y: 65.5358811,
                zoom: 5.5
            },
            desktop: {
                x: -18.959438627730137,
                y: 65.05330975117354,
                zoom: 6.342689142884593
            }
        },
        locations: [

        ],

        /** Payment Providers **/
        paymentProviders: [],
    }),
})

// define routes
const routes = [
    {
        path: '/cords/:lat/:lng/:zoom',
        name: 'cords',
        query: {
            stationId: ''
        }
    },
    {
        path: '/stations/:stationId',
        name: 'stations',
    },
    {
        path: '/stores',
        name: 'stores',
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    // @ts-ignore
    routes,
})

app.use(router)

app.component('font-awesome-icon', FontAwesomeIcon)

app.mount('#app')
