/**
 * Helper methods for various geometry calculations
 */

// Converts from degrees to radians.
export function degToRadians(degrees) {
    return degrees * Math.PI / 180;
}

// Converts from radians to degrees.
export function radToDegrees(radians) {
    return radians * 180 / Math.PI;
}

// Calculate bearing (in degrees), from 2 coordinates
export function calculateBearing(startLat, startLng, destLat, destLng){
    startLat = degToRadians(startLat);
    startLng = degToRadians(startLng);
    destLat = degToRadians(destLat);
    destLng = degToRadians(destLng);

    let y = Math.sin(destLng - startLng) * Math.cos(destLat);
    let x = Math.cos(startLat) * Math.sin(destLat) -
        Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
    let brng = Math.atan2(y, x);
    brng = radToDegrees(brng);
    return (brng + 360) % 360;
}