/**
 * This file holds the mapbox settings store
 *
 *      - Holds the mapbox instance
 *
 *      - Holds the control values for the map, like: zoom, bearing, center, etc..
 */

import {defineStore, storeToRefs} from "pinia";
import {useUiStore} from "@/store/ui-store";
import {getBoundsForLocations} from "@/lib/map";
import {useSettingsStore} from "@/store/settings-store";
//import {useLocationsStore} from "@/store/locations-store";

export const useMapStore = defineStore('map', {
    state: () => ({
        bearing: 0, // Controls the bearing of the map
        bounds: null, // Controls the bounds of the map
        mapboxInstance: null,
        isRotating: false,
    }),
    actions: {
        flyToCoordinates(coordinates, cb = null, zoom = 22) {

            this.mapboxInstance.flyTo({
                center: coordinates,
                animate: true,
                duration: 3350,
                zoom: zoom,
                pitch: 125,
                bearing: this.bearing
            })

            if (cb) {
                cb() // run callback if it is not null
            }
        },
        startCameraRotation(timestamp) {
            this.isRotating = true

            this.rotateCamera(timestamp)
        },
        /** eslint-disable-next-line */
        rotateCamera(timestamp) {
            // clamp the rotation between 0 -360 degrees
            // Divide timestamp by 100 to slow rotation to ~10 degrees / sec
            this.mapboxInstance.rotateTo((timestamp / 400) % 360, { duration: 0, pitch: 125 });

            if(this.isRotating) {
                // Request the next frame of the animation.
                requestAnimationFrame(this.rotateCamera);
            }
        },
        resetCameraRotation() {
            // reset the rotation of the camera
            this.mapboxInstance.rotateTo(0, { duration: 0 })
        },
        stopCameraRotation() {

            if (this.isRotating) {
                this.isRotating = false
            }

            this.mapboxInstance.pitch = 0

            requestAnimationFrame(this.resetCameraRotation)
        },
        fitLocationsToBounds(locations) {
            console.log('fitting locations to bounds')
            const bounds = getBoundsForLocations(locations)

            this.setBounds(bounds)

        },
        setBounds(bounds) {
            this.bounds = bounds
        },
        setMapboxInstance(instance) {
            console.log('setting mapbox instance in store')
            this.mapboxInstance = instance

           const uiStore = useUiStore()

           const { setMapCreated } = uiStore
           setMapCreated() // toggles the visibility

        },
        informResize() {
            this.mapboxInstance?.resize()
        },
        resetMapCamera() {
            /** first we check what device we are on */
            const { deviceType } = storeToRefs(useUiStore())

            const { mapboxSettings } = storeToRefs(useSettingsStore())

            //this.setBounds(mapboxSettings.value.maxBounds)

            console.log('resetting map camera', mapboxSettings.value.target)
            console.log('mapbox settings', mapboxSettings)

            let startPosition = [mapboxSettings.value.startPosition.desktop.x,mapboxSettings.value.startPosition.desktop.y]
            let zoom = mapboxSettings.value.startPosition.desktop.zoom

            if (deviceType.value === 'mobile') {
                startPosition = [mapboxSettings.value.startPosition.mobile.x,mapboxSettings.value.startPosition.mobile.y]
                zoom = mapboxSettings.value.startPosition.mobile.zoom

            }

            if (deviceType.value === 'desktop') {
                startPosition = [mapboxSettings.value.startPosition.desktop.x,mapboxSettings.value.startPosition.desktop.y]
                zoom = mapboxSettings.value.startPosition.desktop.zoom

            }

            if (deviceType.value === 'tablet') {
                startPosition = [mapboxSettings.value.startPosition.tablet.x,mapboxSettings.value.startPosition.tablet.y]
                zoom = mapboxSettings.value.startPosition.tablet.zoom

            }

            console.log('starting position is:', [startPosition, zoom])



            //const { locations } = storeToRefs(useLocationsStore())
            //this.fitLocationsToBounds(locations.value)

            this.mapboxInstance?.flyTo({
                center: startPosition,
                animate: true,
                duration: 1300,
                zoom: 6,
                pitch: 0,
                bearing: 0
            })


        }
    },
    getters: {
        getMapBounds: (state) => state.bounds
    }
})