/**
 * The location store
 *
 *      - Holds the ID for the Navigation tracking id
 *      - Holds the current user location
 *
 *
 *      - Has method to accept GPS coordinates from device, used in the callback,
 *        so we don't directly mutate the coordinate object.
 */

import {defineStore, storeToRefs} from "pinia";
import { useGeolocation } from '@vueuse/core'
import {useUiStore} from "@/store/ui-store";

export const useUserLocationStore = defineStore('user-location', {
    state: () => ({
        /** Holds the tracking id for the navigation **/
        navigationTrackingId: null,

        /** has user accepted consent */
        userLocationConsent: false,

        /** is tracking state */
        isCurrentlyTracking: false,


        /** The current location of the user @currentLocation[x,y] is computed in getter **/
        /**
         * Currently we are directly changing these values, but we will add
         * a class in the middle to handling smoother movement within
         * the map when in driving mode, etc.
         */
        currentLocation: {
            speed: null,
            accuracy: 0,
            longitude: null,
            latitude: null,
            heading: null,
            altitude: null,
            altitudeAccuracy: null
        },

        geoLocationStatus: {
            initialized: false,
            lastReceived: null      // last received gps
        },

        currentBearing: 0

        /** Location H */
    }),
    getters: {
        getUserLocationIsValid: (state) => state.currentLocation.heading !== undefined,
        getUserLocationPinStyle: (state) => ({ transform: 'rotate(' + state.currentLocation.heading + 'turn)'}),
        getUserLocation: (state) => state.currentLocation,
        getUserBearing: (state) => state.currentLocation.heading,
        getIsCurrentlyTracking: (state) => state.isCurrentlyTracking
    },
    actions: {
        initializeGeoLocation() {
            if (! this.geoLocationStatus.initialized) {
                /** If the geolocationStatus has not been initialized */

                const { showMessageBox } = useUiStore()

                showMessageBox('Initializing tracking')

                const { locatedAt, error } = useGeolocation()


                const { coords } = storeToRefs(useGeolocation())

                this.currentLocation = coords
                console.log('got coords:', coords)


                console.log('locatedAt: ', locatedAt)

                error && console.log('error: ', error)

                this.geoLocationStatus = {
                    initialized: true
                }

                setTimeout(() => {
                    const uiStore = useUiStore()

                    const { showUserLocationPin } = uiStore

                    showUserLocationPin()
                }, 200)
            }
        },
        startUserTracking() {
            if (this.userLocationConsent === false) {
                this.askForUserLocationConsent()
            }

            /** Start the tracking if user has consented */
            if (this.userLocationConsent) {
                if (this.geoLocationStatus.initialized === false) {
                    console.log('starting the navigation tracking')
                    this.initializeGeoLocation()
                } else {
                    /** Resume the tracking **/
                    console.log('resuming navigation tracking')

                    const { showMessageBox } = useUiStore()

                    showMessageBox('Resuming tracking')

                    const { resume } = useGeolocation()

                    resume()

                    /** Set isCurrentlyTracking state */
                    this.isCurrentlyTracking = true

                    /** show the user location pin */
                    const { showUserLocationPin } = useUiStore()
                    showUserLocationPin()
                }
            }

        },
        pauseUserTracking() {
            console.log('pausing user tracking')

            if (this.isCurrentlyTracking) {
                const { showMessageBox } = useUiStore()

                showMessageBox('Stöðva staðsetningu')

                /** Pause the tracker, if it exists **/
                const { pause } = useGeolocation()

                pause()

                /** Set user location to empty object **/
                this.currentLocation = {}

                /** Set isCurrentlyTracking state */
                this.isCurrentlyTracking = false

                /** set the pin hide status */
                const { hideUserLocationPin  } = useUiStore()

                hideUserLocationPin()

            }

        },
        enableUserLocationTracking() {
            console.log('tracking enable action called')



            if (this.userLocationConsent === false) {
                this.askForUserLocationConsent()
            }




        },
        disableUserLocationTracking() {

        },
        /**
         * Prompts the user to consent to location
         *
         * Takes in an accept and reject callback,
         * they will be called depending on user input
         *
         * @callback accept
         * @callback reject
         * @void
         */
        askForUserLocationConsent() {
            // this.userLocationConsent = confirm('we need the location bro, do u agree?');


            const { showConfirmDialog } = useUiStore()

            showConfirmDialog(
                'we need the location bro, do u agree?',
                'we use the location to do stuff',
                () => {
                    console.log('accept callback wohoo')
                    /** update the store  */
                    this.userLocationConsent = true

                    /** start the tracking */
                    this.startUserTracking()
                },
                () => {
                    console.log('reject callback wohoo')
                    alert('we need user location brooo!!!')
                },
                {
                    acceptText: 'Leyfa staðsetningu',
                    denyText: 'Hafna'
                }
            )


/*            if (this.userLocationConsent) {
                console.log('was accepted')
                alert('user consent accepted')


            }

            if (!this.userLocationConsent) {
                console.log('was rejected')
                alert('Til að virkja staðsetningu þarftu að gefa síðunni leyfi!')
            }*/

        },

    }
})