<template>
    <MapboxMap
               v-if="getMapShouldLoad"
               class="map-container"
               :class="{ 'mobile' : getDeviceType === 'mobile' }"
               :style="{
                  width: mapWidth,
                  marginLeft:
                  mapLeftWidthOffset,
                  height: mapHeight,
                  marginTop: '4vh',
                  //transform: 'translateY(-' + settings.headerBorderBottomWidth + ' - 1vh)'
               }"
               :map-style="mapboxSettings.styleUrl"
               :access-token="mapboxSettings.apiKey"
               :center="mapCenter"
               :zoom="mapZoom"
               :pitch="mapPitch"
               :maxZoom=25
               :bearing="userBearing"
               :maxBounds="getMapBounds"
               attributionControl=false
               @mb-dragend="handleDragend"
               @mb-created="this.setMapboxInstance"
    >

      <!-- filtered stations -->
      <template v-if="!getShowSearchResults">
        <MapboxMarker v-for="station in getFilteredStations" :ref="'station-' + station.properties.id" :id="'station-' + station.properties.id" :key="station.properties.id" :lng-lat="station.geometry.coordinates" anchor="bottom" >
          <img height="40" width="40" @click="handleIconClick(station)" :src="station.properties.pin" class="custom-marker" > <h3>{{ station.name }}</h3>
        </MapboxMarker>
      </template>



      <!-- search results -->
      <template v-if="getShowSearchResults">
        <MapboxMarker v-for="station in getSearchResults" :ref="'station-' + station.properties.id" :id="'station-' + station.properties.id" :key="station.properties.id" :lng-lat="station.geometry.coordinates" anchor="bottom" >
          <img height="40" width="40" @click="handleIconClick(station)" :src="station.properties.pin" class="custom-marker" > <h3>{{ station.name }}</h3>
        </MapboxMarker>
      </template>


      <UserLocationMarker />

    </MapboxMap>

    <NetworkFilter />
</template>

<script>
import {useStore} from "@/main";
//import Throttler from "@/lib/throttler";
import {useRoute, useRouter} from "vue-router";
import { MapboxMap, MapboxMarker } from '@studiometa/vue-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
//import { AttributionControl } from 'mapbox-gl';
import {getData, getNetworks, getPlugTypes} from "@/lib/api";
//import { getAnalytics, logEvent } from "firebase/analytics";
//import {fitLocationsToBounds} from "@/lib/map";
import NetworkFilter from "@/components/NetworkFilter";
import {useSettingsStore} from "@/store/settings-store";
import {useMapStore} from "@/store/map-store";
import {useUiStore} from "@/store/ui-store";
import {useLocationsStore} from "@/store/locations-store";
import {useUserLocationStore} from "@/store/user-location-store";
import {storeToRefs} from "pinia";
import UserLocationMarker from "@/components/ui/UserLocationMarker.vue";

export default {
  name: "MapContainer",
  components: {NetworkFilter, MapboxMap, MapboxMarker, UserLocationMarker },
  created() {

    this.store = useStore()
    this.router = useRouter()


    /** Fetch networks **/
    getNetworks(this.backendUri)
      .then((data) => {
        this.store.networks = Object.values(data)
        //console.log('Got networks: ', Object.values(data))
        this.store.networks.forEach((network) => {
          if (network.filter_start_active) {
            this.store.filters.networks.push(network.key)
          }
        })
      })
      .catch((error) => {
        console.log(error)
      })

    /** Fetch plug types **/
    getPlugTypes(this.backendUri)
      .then((data) => {
        this.store.plugTypes = Object.values(data)
        this.store.plugTypes.forEach((plugType) => {
          this.store.filters.plugTypes.push(plugType.key)
        })
      })
      .catch((error) => {
        console.log(error)
      })



    console.log('current route', this.$route.name)


  },
  listeners: {
    'searchItemClick': 'handleItemClick'
  },
  setup() {
    const { getMapboxSettings, backendUri } = storeToRefs(useSettingsStore())

    // eslint-disable-next-line no-unused-vars
    const {
      getMapVisibility,
      getMapShouldLoad,
      getSidebarOpen,
      getUserHasSearched,
      getDeviceType,
      getShowUserLocationPin,
      getShowSearchResults,
      getSearchResults
    } = storeToRefs(useUiStore())


    const { showMap, setSidebarOpen } = useUiStore()


    // get the map store
    const { getMapBounds, mapboxInstance } = storeToRefs(useMapStore())

    const { setMapboxInstance, informResize, flyToCoordinates } = useMapStore()

    const { getPinnedLocations } = storeToRefs(useLocationsStore())

    const { loadLocations, setSelectedLocation } = useLocationsStore()

    const { getIsCurrentlyTracking } = storeToRefs(useUserLocationStore())

    return {
      getMapVisibility,
      setMapboxInstance,
      showMap,
      loadLocations,
      getFilteredStations: getPinnedLocations,
      mapboxSettings: getMapboxSettings,
      backendUri,
      getMapShouldLoad,
      informResize,
      flyToCoordinates,
      setSidebarOpen,
      getMapBounds,
      getSidebarOpen,
      getUserHasSearched,
      getDeviceType,
      getIsCurrentlyTracking,
      getShowUserLocationPin,
      setSelectedLocation,
      mapboxInstance,
      getShowSearchResults,
      getSearchResults
    }
  },
  mounted() {
     /** Load the map **/
    window.addEventListener('load', (e) => {
      console.log('map loaded, alert the ui that the settings are here -> showMap', e)

/*      /!** use the mapbox instance to add an attribution link **!/
      setTimeout(() => {
        this.mapboxInstance.attributionControl.removeAttribution('© <a href="https://www.mapbox.com/about/maps/" target="_blank">Mapbox</a>')
      }, 100)*/

//      this.mapboxInstance.attributionControl.addAttribution('© <a href="https://www.openchargemap.io/" target="_blank">Open Charge Map</a>')
    })
    /** alert the ui that the settings are here -> showMap */
    console.log('map loaded, alert the ui that the settings are here -> showMap')
    setTimeout(this.showMap, 300)
  },
  computed: {
    mapPitch: {
      get() {
        return this.store.mapPitch
      },
      set(mapPitch) {
        this.store.mapPitch = mapPitch
      }
    },
    userHasSearched() {
      return this.store.userHasSearched
    },
    maxBounds() {
      return this.store.maxBounds
    },
    mapHeight() {
      if (this.getDeviceType === 'mobile' && this.getSidebarOpen) {
         return '56.3vh'
      }
      return '96vh'
    },
    mapWidth() {
      if (
          (this.getDeviceType === 'desktop' && this.getSidebarOpen) ||
          (this.getDeviceType === 'desktop' && this.getUserHasSearched)
      ) {
        return '75vw'
      }
      return '100vw';
    },
    mapLeftWidthOffset() {
      if (
          (this.getDeviceType === 'desktop' && this.getSidebarOpen) ||
          (this.getDeviceType === 'desktop' && this.getUserHasSearched)
      ) {
        return '25vw'
      }
      return '0vw'
    },
    mapCenter: {
      get() {
        return this.store.currentPosition
      },
      set(coordinates) {
        this.store.currentPosition = coordinates
      }
    },
    stations: {
      get() {
        return this.getFilteredStations
      },
      set(stations = null) {
        console.log('cannot set filtered stations: not supported', stations)
      }
    },
    activeStation: {
      get() {
        return this.store.activeStation
      },
      set(station) {
        this.store.activeStation = station
      }
    },
    mapZoom: {
      get() {
        return this.store.currentZoom
      },
      set(zoom) {
        this.store.currentZoom = zoom
      }
    },
    infoboxItemId: {
      get() {
        return this.store.infoboxItemId
      },
      set(itemId) {
        this.store.infoboxItemId = itemId
      }
    },
    apiKey: {
      get() {
        return this.store.mapboxApiKey
      },
      set(apiKey) {
        console.log('setting api key to: ', apiKey)
        this.store.mapboxApiKey = apiKey
      }
    },
    directionSearch: {
      get() {
        return this.store.directionSearch
      },
      set(value) {
        this.store.directionSearch = value
      }
    }
  },
  watch: {
    deviceType() {
      console.log('deviceType changed', this.deviceType)
      //console.log('deviceType changed', this.storedDeviceType)
      this.getStartPosition()
    }
  },
  methods: {
    handleItemClick(itemId) {
      console.log('i am handling the item click: from map container, itemId: ', itemId)
    },
    handleIconClick(station) {

      console.log('icon was clicked, search direction is', this.directionSearch)

      if (this.directionSearch) {
        console.log('nope')
        return
      } else {

        // try {
        //   const analytics = getAnalytics()
        //
        //   // Tell analytics that the user clicked on a station
        //   logEvent(analytics, 'station_click', {
        //     station_id: station.id,
        //     station_name: station.name
        //   })
        //
        // } catch (e) {
        //   // There was an error fetching the analytics instance.
        //   console.log('errors', e)
        // }


        console.log('icon clicked, station id:', station.properties.id)

        // First find the station longitude and latitude from stations array
        //let station = this.stations.find(station => station.properties.id === stationId)

        //this.store.currentPosition = station.geometry.coordinates
        //this.store.currentZoom = 17

        this.setSelectedLocation(station)

        // this.infoboxItemId = station.properties.id
        //
        // this.activeStation = station
        //
        // this.$router.push({
        //   name: 'cords',
        //   params: {
        //     lat: station.geometry.coordinates[1],
        //     lng: station.geometry.coordinates[0],
        //     zoom: 15
        //   },
        //   query: {
        //     stationId: station.properties.id
        //   }
        // })
        //
        // this.store.activeStation = station

        this.setSidebarOpen()

        setTimeout(() => {
          this.informResize()
        }, 100)
      }
    },
    handleZoomEvent(e) {
      return e
    },
    handleDragend(e) {
      return e
    },
    async getStartPosition() {

      const router = useRouter();
      const route = useRoute();

      router.isReady().then(() => {
        console.log('router is ready')
        console.log('route name is', route.name)
        let routeParams = route.params
        console.log('routeParams', routeParams)

        if (route.name === 'cords') {
          console.log('ROute is CORDS:', routeParams)
          let cordsPos = [routeParams.lat, routeParams.lng]
          let cordsZoom = routeParams.zoom
          console.log('cords', cordsPos, cordsZoom)

          this.mapboxInstance.flyTo({
            center: cordsPos,
            zoom: cordsZoom,
            speed: 2,
          });

          // reference the variables for vue to update the computed properties
          console.log('mapCenter', this.mapCenter)
          console.log('mapZoom', this.mapZoom)

        } else if (route.name === 'stations') {

          let stationId = route.params.stationId

          console.log('stationId', stationId)
          this.store.$patch({currentPosition: [this.store.startPosition.desktop.x, this.store.startPosition.desktop.y]})
          this.store.$patch({currentZoom: this.store.startPosition.desktop.zoom})

          console.log('stations we have now', this.stations)

          getData('stations').then((stations) => {
            this.stations = stations
            console.log('stations we have now', this.stations)
            let station = this.stations.filter(station => station.properties.id === stationId)
            console.log('station', station)
            this.store.$patch({currentPosition: station.geometry.coordinates})
            this.store.$patch({currentZoom: 16})
            this.store.$patch({activeStation: station})
            this.store.$patch({sidebarOpen: true})
            this.store.$patch({infoboxItemId: stationId})
          })

/*
          // Next we get the station from the stations array
          this.stations.forEach(station => {
            console.log('station', station)
            if (String(station.properties.id) === stationId) {
              console.log('station found', station)
              this.store.$patch({activeStation: station})
              this.store.$patch({sidebarOpen: true})
              this.store.$patch({infoboxItemId: stationId})

              // Then set the map center to the station coordinates
              this.mapboxInstance.flyTo({
                center: station.geometry.coordinates,
                zoom: 16,
                speed: 1.2,
                curve: 1.3,
                easing: function (t) {
                  return t;
                }
              });
            }
          })
*/


        } else {
          if (this.deviceType === 'mobile') {

            this.mapboxInstance.flyTo({
              center: [this.store.startPosition.mobile.x, this.store.startPosition.mobile.y],
              zoom: this.store.startPosition.mobile.zoom,
              speed: 2
            });

          } else if (this.deviceType === 'tablet') {
            this.mapboxInstance.flyTo({
              center: [this.store.startPosition.tablet.x, this.store.startPosition.tablet.y],
              zoom: this.store.startPosition.tablet.zoom,
              speed: 2,

            });
          } else {
            this.mapboxInstance.flyTo({
              center: [this.store.startPosition.desktop.x, this.store.startPosition.desktop.y],
              zoom: this.store.startPosition.desktop.zoom,
              speed: 2
            });
          }
        }
      });


      //console.log('The device type started as: ', this.storedDeviceType)
      //console.log('Local prop device type: ', this.deviceType)

    }
  },
  data() {
    return {
      router: null,
      points: [

      ]
    }
  }
}
</script>

<style scoped>

  .marker-highlight {
    background-color: #1ED55F;
    border: 2px solid #1ED55F;
    box-shadow: #1ED55F 0px 0px 4px 2px;
  }

  .map-container {
    height: 93vh; width:100%;
    transition: all 0.7s ease-in-out;
  }

  .mobile {
    height: 50vh !important;
  }

  .custom-marker {
    /*box-shadow: #1ED55F 0px 0px 4px 2px;*/ /* green shadow */
    cursor: pointer;
    border-radius: 50%;
  }
</style>