<template>
  <div
      v-if="settings.showSearch && true === false"
      :class="searchContainerClass"
      class="search-container"
      :style="{ 'background-color': settings.searchBoxBackgroundColor, 'z-index': 1000 }"
  >
      <div class="icon-container">
        <font-awesome-icon

            icon="fa-solid fa-magnifying-glass"
            @click="toggleExpandSearchWindow"
            :style="{ 'color': settings.searchBoxIconColor }"
        />
      </div>
      <input
          v-if="expanded"
          class="searchInputField"
          spellcheck="false"
          type="search"
          ref="searchInput"
          @input="debounceInput"
          v-model="term"
          placeholder="Leita að hleðslustöð"
          :style="{
            'background-color': settings.searchBoxBackgroundColor,
            'color': settings.searchBoxTextColor,
          }"
      >

      <!-- put a font-awesome-icon that shows a X to close here -->
      <i v-if="searching && expanded" class="fas fa-spinner fa-pulse"></i> <!-- TODO: Replace with font-awesome-icon -->
      <button v-if="search.userHasSearched && expanded" @click="resetSearch" type="button" class="close-btn">
        <svg width="24" height="24" viewBox="0 0 24 24">
          <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
        </svg>
      </button>
      <button v-if="!search.userHasSearched && expanded" class="close-btn">
        <!-- add a font-awesome-icon here -->



      </button>
  </div>
</template>

<script>
import {useStore} from "@/main";
import {searchStation} from "@/lib/api";
import {getAnalytics, logEvent} from "firebase/analytics";
import {fitLocationsToBounds} from "@/lib/map";
import {useSettingsStore} from "@/store/settings-store";
import {useUiStore} from "@/store/ui-store";
import {storeToRefs} from "pinia";
import {useMapStore} from "@/store/map-store";

export default {
  name: "SearchBox",
  props: {
    deviceType: String
  },
  data() {
    return {
      term: '',
      typing: false,
      debounce: null,
      searching: false,
    }
  },
  watch: {
    term() {
      this.$emit('searchInit', this.term)
      console.log('Term: ', this.term)

      this.setSearchQuery(this.term)

      if (this.search.userHasSearched && this.term === '') {
        /**
         * If the user has searched, and he deletes the
         * search term we need to reset the search to
         * remove the X button
         */
        console.log('restting search')
        this.resetSearch()
      }
    },
    typing() {
      //console.log('Typing: ', this.typing)

    }
  },
  setup() {

    const {
      getSidebarOpen,
      getSearchResults,
      getSearchExpanded
    } = storeToRefs(useUiStore())

    const { search } = storeToRefs(useUiStore());

    const { resetSearch, expandSearch, setSearchQuery, collapseSearch, setSearchResults, setSidebarOpen } = useUiStore()

    const { mapboxInstance } = storeToRefs(useMapStore())

    const { frontendSettings } = storeToRefs(useSettingsStore())

    return {
      search,
      resetSearch,
      mapboxInstance,
      settings: frontendSettings,
      sidebarOpen: getSidebarOpen,
      searchResults: getSearchResults,
      expanded: getSearchExpanded,
      expandSearch,
      setSearchQuery,
      collapseSearch,
      setSidebarOpen,
      setSearchResults
    }
  },
  created() {
    this.store = useStore()
    console.log(this.expanded)
  },
  computed: {
    searchContainerClass() {
      if (this.expanded) {
        return 'search-container expanded ' + this.deviceType
      } else {
        return 'search-container ' + this.deviceType
      }
    },
    stations: {
      get() {
        return this.store.stations
      },
      set(stations) {
        this.store.stations = stations
      }
    },

  },
  methods: {
    toggleExpandSearchWindow() {
      console.log('toggling search window')

      if (this.expanded) {
        console.log('collapsing search window')
        this.collapseSearch()
      } else {
        console.log('expanding search window')
        this.expandSearch()


        setTimeout(() => {
          this.$refs.searchInput.focus()
        }, 100)
      }


    },
    debounceInput(event) {
      console.log('Debounce search', event.target.value)
      //this.term = event.target.value
      this.typing = true
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.typing = false
        this.term = event.target.value
        this.store.userHasSearched = true

        if (this.userHasSearched && this.term === '') {
          console.log('i am going to reset search')
          // Resets the form
          this.resetSearch()
        } else {
          this.debounceSearch()
        }
      }, this.settings.searchDebounceTime)
    },
    // resetSearch() {
    //   this.expanded = false
    //   this.term = ''                      // set component level
    //   this.store.userHasSearched = false  // global level in store
    //   this.$refs.searchInput.value = ''   // set DOM level
    //   this.store.sidebarOpen = false      // close sidebar
    //   this.store.activeStation = null     // remove active station
    //   this.store.openInfoboxId = null     // remove open infobox
    //   this.mapboxInstance.resize()        // resize map
    //
    //   // reset search results array
    //   this.searchResults = []
    //
    //   // set the default stations
    //   getData().then((data) => {
    //     this.stations = data
    //     fitLocationsToBounds(this.mapboxInstance, data) // center the map on the stations
    //   })
    //
    //
    // },
    debounceSearch() {
      // Don't search for empty string, replace the stations with all the stations
      if (this.term === '') {
        this.resetSearch()
        console.log('empty term in debounce search')
        // getData().then((data) => {
        //   this.stations = data
        // })
        return
      }
      console.log('Search started for: ', this.term)
      setTimeout(() => {
        console.log('... searching ....')
        this.searching = true

        try {
          const analytics = getAnalytics()

          // Tell analytics that the user clicked on a station
          logEvent(analytics, 'user_searched', {
            term: this.term
          })

        } catch (e) {
          // There was an error fetching the analytics instance.
          console.log('error', e)
        }

        // Close the mobile keyboard
        this.$refs.searchInput.blur()

        searchStation(this.term).then((data) => {

          console.log('Search response: ', data)
          this.searching = false
          this.stations = Object.values(data)

          this.searchResults = Object.values(data)

          console.log('type of data: ', typeof(Object.values(data)))


          //console.log('DATA:: ', data)

          /**
           * We need to fit the map to the bounds of the search results
           */
          let locations = Object.values(data)

          this.setSearchResults(locations)

          /** If there are more than 1 locations, calculate the center **/
          if (locations.length > 1) {
            fitLocationsToBounds(this.mapboxInstance, locations)

            /** Open the sidebar */
            this.setSidebarOpen()
            this.searching = false


            return
          }



          /** If there is only location in result, fly to location and open infobox **/
          if (locations.length === 1) {
            let location = locations[0]
            this.mapboxInstance.flyTo({
              center: location.geometry.coordinates,
              zoom: 15
            })
            this.store.sidebarOpen = true
            this.mapboxInstance.resize()  // let mapbox know that the sidebar is open
            this.store.activeStation = location
            this.store.openInfoboxId = location.properties.id
            return
          }

          /** If there are no locations, show a message to the user **/
          if (locations.length === 0) {
            console.log('No locations found')
            return
          }
/*          if (locations.length === 0) {
            // If there are no results, we need to fit the map to the default stations
            getData().then((data) => {
              let bounds = getBoundsForLocations(Object.values(data))
              fitMapToBounds(this.mapboxInstance, bounds)
            })
          }*/

          //fitLocationsToBounds(this.mapboxInstance, getBoundsForLocations(Object.values(data)))

          /**
           * If the search returns only one result, we want to
           * open the sidebar and show the infobox for that station
           *
           * TODO[]
           */

        }).catch((error) => {
          console.log('Search error: ', error)
          this.searching = false
        })
      }, 200)

    }
  }
}
</script>

<style scoped>

/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

.searchInputField {
  font-size:1.3rem;
  font-weight: bold;
}

.search-container {
  position: fixed;
  top: 12vh;
  transform: translate(-50%, -50%);
  width:3.4rem;
  left: 5%;
  height: 6vh;
  min-height: 40px;
  border-radius: 12px;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
}

.search-container.mobile {
  top: 20vh !important;
}

.search-container.expanded {
  left: 50%;
  min-width: 280px;
  width: 26vw;

}

.search-icon {
  position: fixed;
  top: 12vh;
  left: 2.1vw;
  min-height: 50px;
  min-width: 50px;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  align-items: center;
  border-radius: 12px;
}



.search-container.mobile {
  width: 14vw;
  height: 6vh;
  top: 14vh;
}

.search-container.mobile:focus-visible {
  transform: translateX(-10vh);
}

.search-container.mobile.expanded {
  max-height: 70vh !important;
  width: 80vw;
}

.search-container input[type="search"] {
  margin-left:0.2rem;
  width: calc(100% - 100px);
  box-sizing: border-box;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  text-align: left;
  direction: ltr;
}

.search-container input[type="search"]:focus {
  outline: none;
}

.search-container input[type="search"]::placeholder {
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  color: white;
}

.search-container.mobile input[type="search"]::placeholder {
  font-size: 0.9rem;
}

.icon-container {
  font-size: 1.5rem;
  padding-left: 15px;
  display: flex;
  align-items: center;
}

.mobile .icon-container {
  top: 15%;
}

.icon-container.right-icon-container {
  float: right;
}

.search-container .close-btn {
  color: white;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.search-container .close-btn:hover {
  color: #ddd;
}

</style>