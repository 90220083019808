<template>
  <div class="connection-type-card">
    <!-- sub heading -->
    <h4 class="sub-heading text-white">Charging points</h4>

    <div class="accordion" role="tablist">


      <b-card no-body class="mb-1" v-for="plug in plugs" :key="plug.name">
        <b-card-header header-tag="header" class="p-1 plug" role="tab" @click="toggleCollapse(plug.name)">
          <span class="p-2">
            <i v-if="plug.name === activePlug" class="arrow up"></i>
            <i v-if="plug.name !== activePlug" class="arrow down"></i>
          </span>
          <span class="text-black">{{ plug.kw }} kW {{ plug.name }}</span>
            <img :src="plug.image" style="max-height: 60px;" alt="">
            <span :class="{ 'in-use': plug.in_use === plug.count, 'statusText': true }">{{ plug.in_use }}/{{ plug.count }}</span>


        </b-card-header>
        <b-collapse id="accordion-1" :visible="activePlug === plug.name" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text :class="{ 'text-black' : !dark }" v-for="point in plug.chargingPoints" :key="point.title">
              {{ point.title }} <span :style="{ color: point.color }">{{ point.status }}</span>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>




    </div>



  </div>
</template>

<script>
export default {
  name: "ConnectionType",
  props: {
    dark: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleCollapse(activePlug) {
      if (activePlug === this.activePlug) {
        this.activePlug = null;
      } else {
        this.activePlug = activePlug;
      }
    }
  },
  data() {
    return {
      activePlug: '',
      plugs: [
        {
          name: 'CCS',
          image: '/plugs/ccs_plug_type2.svg',
          kw: 100,
          count: 4,
          in_use: 4,
          chargingPoints: [
            { title: 'IS*N100001-1082-2', status: 'Occupied', color: 'red' },
            { title: 'IS*N100002-1082-2', status: 'Occupied', color: 'red' },
            { title: 'IS*N100003-1082-2', status: 'Occupied', color: 'red' },
            { title: 'IS*N100004-1082-2', status: 'Occupied', color: 'red' },
          ]
        },
        {
          name: 'CHAdeMO',
          image: '/plugs/chademo_plug.svg',
          kw: 50,
          count: 2,
          in_use: 0,
          chargingPoints: [
            { title: 'IS*N100005-1200-2', status: 'Available', color: 'rgb(0, 156, 26)' },
            { title: 'IS*N1P00006-1200-2', status: 'Available', color: 'rgb(0, 156, 26)' },
          ]
        }
      ]
    }
  }
}
</script>

<style scoped>
.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

  .content {
    padding: 0 18px;
    display: none;
    overflow: hidden;
  }

  .collapsible {
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
  }

  h4.sub-heading {
    padding: 1rem;
    padding-bottom: 0.1rem;
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .connection-type-card {
  }

  .plug-list {
    display: flex;
  }

  .plug-list > .plug {
    text-align: center;
  }

  .plug > span {
    font-size: 1.1rem;
    font-weight: 600;
    color: rgb(0, 156, 26)
  }

  .plug > span.in-use {
    color: red;
  }

  .box {
    /*
       Make box unselectable
     */
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;

    /*
      Introduced in IE 10.
      See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
    */
    -ms-user-select: none;
    user-select: none;

    margin-left: 10%;
    width: 80%;
    border: 1px solid #ccc;
    box-shadow: rgba(0,0,0,30%) 0px 0px 12px 0px;
    border-radius: 13px;
  }

  .box > h4 {
    font-size: 0.75rem;
    font-weight: 500;
    margin: 0.5rem 0;
  }

  .box > h5 {
    font-size: 0.7rem;
    font-weight: 400;
    margin: 0.5rem 0;
  }

  .box > h6 {
    font-size: 0.6rem;
    font-weight: 400;
    margin: -0.3rem 0 0.5rem 0;
  }

  .plug-list img {
    filter: invert(0.3) sepia(2) saturate(7) hue-rotate(70deg);
    width: 90%;
    height: 14vh;
    margin-top:-1rem;
    margin-bottom:-1.9rem;
  }
</style>