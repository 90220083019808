import { defineStore } from "pinia";
import {getFrontendSettings, getMapSettings} from "@/lib/api";

//import { getRemoteConfig, fetchAndActivate, getAll, getString } from "firebase/remote-config"
//import {useFirebaseStore} from "@/store/firebase-store";
//import {useUiStore} from "@/store/ui-store";
//import {useUiStore} from "@/store/ui-store";

/**
 *  Initial state for settings
 */
const defaultSettingsState = ()  => ({
    remoteConfig: {
        backend_uri: "https://hlada.fotbolti.is/api"
    },
    mapboxSettings: {
        apiKey: "",
        startPosition: {
            tablet: {

            },
            mobile: {

            },
            desktop: {

            }
        },
        maxBounds: []
    },
    frontendSettings: {
        navbarLogo: "storage/P6DBzcwm4euzRrKaVLnHopvzPsaNBM-metaaGxhZGEtbG9nby5wbmc=-.png",
        navbarLogoAlt: "Hlada.is",
        pageTitle: "Hlaða.is",
        networkFilterExpanded: false,
        plugTypeFilterExpanded: false,
        networkFilterExpandedIcon: "storage/iAaXnLJRvly0bt30B05bA3frxPR4G6-metabmV0d29yay1jb2xsYXBzZWQtaWNvbi5wbmc=-.png",
        networkFilterCollapsedIcon: "storage/aNh6u71RZAvoExKWOFCYWgOe8YyGKk-metaZXhwYW5kZWQtaWNvbi5wbmc=-.png",
        plugTypeFilterExpandedIcon: "storage/wa1fmPlR3ira4tPM69b9YZV39cdYmY-metacGx1Zy1jb2xsYXBzZWQtaWNvbi5wbmc=-.png",
        plugTypeFilterCollapsedIcon: "storage/oXd2296VpVAEG7XJvBaXBXKydqBWPf-metaZXhwYW5kZWQtaWNvbi5wbmc=-.png",
        showMoreNetworksExpandedIcon: "",
        showMoreNetworksCollapsedIcon: "",
        showMorePlugTypesExpandedIcon: "",
        showMorePlugTypesCollapsedIcon: "",
        showEditLocationButtonInCard: true,
        headerBackgroundColor: "#000000",
        headerBorderBottomColor: "#1ed55f",
        headerBorderBottomWidth: "2px",
        showSearch: true,
        searchDebounceTime: 2100,
        searchBoxBackgroundColor: "#1ed55f",
        searchBoxTextColor: "#000000",
        searchBoxIconColor: "#000000",
        searchResultsBackgroundColor: "#212529",
        searchResultsTextColor: "#ffffff",
        searchResultsHeaderText: "Leitarniðurstöður",
        searchResultItemShowOnMapButtonText: "Sýna á korti",
        searchResultItemShowOnMapButtonBackgroundColor: "#0d6efd",
        searchResultItemShowOnMapButtonTextColor: "#000000",
        cardHeaderBackgroundColor: "#0d6efd",
        cardHeaderTextColor: "#ffffff",
        drivingModeEnabled: false,
        drivingModeEnabledIcon: '',
        drivingModeDisabledIcon: '',
        // text for network filters
        openNetworkFiltersHelpText: "Opnaðu lista til að sýna/fela hleðslukerfi",
        closeNetworkFiltersHelpText: "Lokaðu lista til að sýna/fela hleðslukerfi",
        showMoreNetworkFiltersHelpText: "Sýna fleiri hleðslukerfi",
        showLessNetworkFiltersHelpText: "Sýna færri hleðslukerfi",
        showNetworkFilterHelpText: "Sýna {network} hleðslukerfi",
        hideNetworkFilterHelpText: "Fela {network} hleðslukerfi",
        openPlugTypeFiltersHelpText: "Opnaðu lista til að sýna/fela hleðslutegundir",
        closePlugTypeFiltersHelpText: "Lokaðu lista til að sýna/fela hleðslutegundir",
        showMorePlugTypeFiltersHelpText: "Sýna fleiri hleðslutegundir",
        showLessPlugTypeFiltersHelpText: "Sýna færri hleðslutegundir",
        showPlugTypeFilterHelpText: "Sýna {plugType} hleðslutegundir",
        hidePlugTypeFilterHelpText: "Fela {plugType} hleðslutegundir",

        // driving mode & user location
        drivingModeEnableIconHelpText: "Virkja bílsviðmót",
        drivingModeDisableIconHelpText: "Slökkva á bílsviðmóti",
        userLocationEnableIconHelpText: "Virkja staðsetningu",
        userLocationDisableIconHelpText: "Slökkva á staðsetningu",
        userLocationConsentMessage: "Vinsamlegast virkja staðsetningu í vafra til að sýna hleðslustöðvar í nærri umhverfi.",
        userLocationButtonApproveText: "Virkja staðsetningu",
        userLocationButtonApproveTextColor: "#000000",
        userLocationButtonApproveBackgroundColor: "#00ff00",
        userLocationButtonDenyText: "Hafna staðsetningu",
        userLocationButtonDenyTextColor: "#000000",
        userLocationButtonDenyBackgroundColor: "#ff0000",
        drivingModeEnabledMessage: "Bílviðmót virkjað",
        drivingModeEnabledMessageColor: "#000000",
        drivingModeEnabledMessageBackgroundColor: "#00ff00",
        drivingModeDisabledMessage: "Bílviðmót óvirkjað",
        drivingModeDisabledMessageColor: "#000000",
        drivingModeDisabledMessageBackgroundColor: "#ff0000",
        userLocationEnabledMessage: "Staðsetning virkjuð",
        userLocationEnabledMessageColor: "#000000",
        userLocationEnabledMessageBackgroundColor: "#00ff00",
        userLocationDisabledMessage: "Staðsetning slökkt",
        userLocationDisabledMessageColor: "#000000",
        userLocationDisabledMessageBackgroundColor: "#ff0000",
        userLocationErrorGettingLocationMessage: "Ekki tókst að sækja staðsetningu",
        userLocationErrorGettingLocationMessageColor: "#000000",
        userLocationErrorGettingLocationMessageBackgroundColor: "#ff0000",
        userLocationErrorGettingLocationDisabledMessage: "Ekki tókst að sækja staðsetningu, staðsetning er óvirk",
        userLocationErrorGettingLocationDisabledMessageColor: "#000000",
        userLocationErrorGettingLocationDisabledMessageBackgroundColor: "#ff0000",

        // user location
        userLocationFilterEnabledIcon: "https://via.placeholder.com/50/00ff00/?text=UL",
        userLocationFilterDisabledIcon: "https://via.placeholder.com/50/ff0000/?text=UL"
    }
})

/**
 * The getters for the settings store
 */
const getters = {
    getMapboxSettings: (state) => state.mapboxSettings,
    backendUri: (state) => state.remoteConfig.backend_uri
}

const actions = {
    async loadAll() {
        /**
         * Correct order of chaining methods, each running after successful one
         */
        await this.loadMapboxSettings()
        await this.loadRemoteSettings()
    },

    async loadRemoteConfig(cb = null) {
        console.log('not running callback, remote config disabled: ', cb)
        /** currently not used **/
        return null
        // const firebaseStore = useFirebaseStore()
        // const remoteConfig = getRemoteConfig(firebaseStore.instance)
        //
        // await fetchAndActivate(remoteConfig)
        //
        // console.log('Remote config received', getAll(remoteConfig))
        //
        // this.remoteConfig = getAll(remoteConfig)
        //
        // this.remoteConfig = {
        //     backend_uri: getString(remoteConfig, 'backend_uri')
        // }
        //
        // if (cb) {
        //     console.log('running callback after loadRemoteConfig')
        //     cb()
        // }

    },

    async loadRemoteSettings(cb = null) {

        try {
            getFrontendSettings(this.backend_uri)
                .then((data) => {
                    this.frontendSettings = data

                    if (cb) {
                        console.log('running callback after getFrontendSettings')
                        cb()
                    }

                })



        } catch (error) {
            console.log('error', error)
        }
    },
    async loadMapboxSettings(cb = null) {
        try {
            getMapSettings(this.backend_uri)
                .then((data) => {
                    //console.log('Loading remote mapbox settings', data)
                    this.mapboxSettings.apiKey = data.apiKey
                    this.mapboxSettings.styleUrl = data.mapboxStyle

                    this.mapboxSettings.startPosition.mobile = data.defaultStartPositionMobile
                    this.mapboxSettings.startPosition.desktop = data.defaultStartPositionDesktop
                    this.mapboxSettings.startPosition.tablet = data.defaultStartPositionTablet

                    this.mapboxSettings.maxBounds = [
                        [data.maxBounds[0].x, data.maxBounds[0].y],
                        [data.maxBounds[1].x, data.maxBounds[1].y]
                    ]

                    if (cb) {
                        console.log('running callback after getting mapbox remote settings, before showMap')
                        cb()
                    }


                })
        } catch (e) {
            console.log('Error fetching mapbox settings')
        }
    }


}

// Define the settings store
export const useSettingsStore = defineStore('settings', {
    state: defaultSettingsState,
    getters,
    actions
})