<template>
  <div class="p-3" :style="{ color: settings.headerBorderBottomColor }" v-if="apiResponse === null">
    <p class="text-white-50">Fáðu leiðarlýsingu á þinn áfangastað
      <a class="m-1 pt-1 btn btn-danger text-white" @click="setDirectionSearchClosed">x</a>
    </p>


    <p class="text-white btn btn-info" v-if="canDoSearch" @click="resetSearch">Hreinsa leit</p>

    <div class="row search-input">

      <aside>
        <h3 class="text-white">Byrjunarstaður</h3>

        <p v-if="startLongitude === 0 && startLatitude === 0">
          Vinsamlegast veldu staðsetingu
        </p>

        <div class="row p-2" v-if="startSelectionType === ''">
          <div class="col-2">
            <button class="btn btn-outline-primary text-white btn mb-2">Leita</button>
          </div>
          <div class="col-6 offset-1">
            <button class="btn btn-outline-primary text-white btn mb-2">Velja hleðslustöð</button>
          </div>
          <div class="col-3">
            <button class="btn btn-outline-primary text-white btn mb-2" @click="startPosByGps">GPS</button>
          </div>
        </div>

        <div class="row col-10 offset-1">
          <searchable-dropdown v-if="startSelectionType === 'select'" :options="stations" @selected-id="handleSelectStationStart"></searchable-dropdown>
          <place-search-box v-if="startSelectionType === 'search'" @selected-coordinates="handleSelectCoordinatesStart"></place-search-box>
        </div>
      </aside>


      <!-- Velja -->


      <aside>
        <h3 class="pt-2 text-white">Áfangastaður</h3>

        <p v-if="endLatitude === 0 && endLongitude === 0">
          Vinsamlegast veldu staðsetingu
        </p>


        <searchable-dropdown v-if="destinationSelectionType === 'select'" :options="stations" @selected-id="handleSelectStationEnd"></searchable-dropdown>
        <place-search-box v-if="destinationSelectionType === 'search'" @selected-coordinates="handleSelectCoordinatesEnd"></place-search-box>

      </aside>




      <div class="d-none">
        <div class="col-6 ">
          <p>Latitude</p>
          <input type="text" v-model="startLatitude">

        </div>
        <div class="col-6">
          <p>Longitude</p>
          <input type="text" v-model="startLongitude">

        </div>



        <div class="col-6 ">
          <p>Latitude</p>
          <input type="text" v-model="endLatitude">

        </div>
        <div class="col-6">
          <p>Longitude</p>
          <input type="text" v-model="endLongitude">

        </div>
      </div>


      <div class="p-2">
        <button class="btn btn-primary" v-if="canDoSearch" @click="searchForDirections">Finna leiðarlýsingu</button>
      </div>

    </div>


  </div>
<!--  <div class="directions" v-if="!apiResponse">
    <h4>There is a response</h4>
    <pre>
        {{ apiResponse }}
      </pre>
  </div>-->
</template>

<script>


import {useStore} from "@/main";
import {getDirections} from "@/lib/mapbox";
import {getBoundsForCoordinate2d} from "@/lib/map";
import {addAnalyticsEvent} from "@/lib/analytics";
import {calculateBearing} from "@/lib/math";
import SearchableDropdown from "@/components/SearchableDropdown";
import {useSettingsStore} from "@/store/settings-store";
import PlaceSearchBox from "@/components/ui/PlaceSearchBox";
import {useUiStore} from "@/store/ui-store";
import {storeToRefs} from "pinia";
import {useMapStore} from "@/store/map-store";
import {useDrivingStore} from "@/store/driving-mode-store";

export default {
  name: "DirectionSearch",
  components: {PlaceSearchBox, SearchableDropdown},
  setup() {
    const { setDirectionSearchClosed } = useUiStore()

    const { mapboxInstance } = storeToRefs(useMapStore())

    return {
      setDirectionSearchClosed,
      mapboxInstance
    }
  },
  created() {
    this.store = useStore()
  },
  data() {
    return {
      showResults: false,
      selectedStartStation: null,
      selectedEndStation: null,
      startLatitude: 0,
      startLongitude: 0,
      endLatitude: 0,
      endLongitude: 0,
      apiResponse: null,
      station: null,
      startSelectionType: 'search',
      destinationSelectionType: 'search'
    }
  },
  watch: {
    selectedStartStation(value) {
      if (value !== null) {
        this.startLatitude = value[1]
        this.startLongitude = value[0]
      }
    },
    selectedEndStation(value) {
      if (value !== null) {
        this.endLatitude = value[1]
        this.endLongitude = value[0]
      }
    }
  },
  methods: {
    resetSearch() {
      this.startLongitude = 0
      this.startLatitude = 0
      this.endLatitude = 0
      this.endLongitude = 0
    },
    startPosByGps() {
      this.startLongitude = this.store.userLocation[0]
      this.startLatitude = this.store.userLocation[1]
    },
    searchForDirections() {


      getDirections(
          this.startLatitude,
          this.startLongitude,
          this.endLatitude,
          this.endLongitude,
      ).then(res => {

        const { geojson, legs } = res
        this.apiResponse = geojson
        this.showResults = true

        if (this.mapboxInstance.getSource('route')) {
          this.mapboxInstance.getSource('route').setData(geojson)
        } else {
          this.mapboxInstance.addLayer({
            id: 'route',
            type: 'line',
            source: {
              type: 'geojson',
              data: geojson
            },
            layout: {
              'line-join': 'round',
              'line-cap': 'round'
            },
            paint: {
              'line-color': this.settings.headerBorderBottomColor,
              'line-width': 5,
              'line-opacity': 0.75
            }
          });
        }


        console.log('legs:' , legs)
        const { setLegs } = useDrivingStore()
        setLegs(legs)

        //console.log('geojson:', res.geojson)



        // calculate the map bounds
        const bounds = getBoundsForCoordinate2d(geojson.geometry.coordinates)

        this.mapboxInstance.fitBounds(bounds, {
          padding:20,
        })



        addAnalyticsEvent('user_searched_for_directions', {
          startLatitude: this.startLatitude,
          startLongitude: this.startLongitude,
          endLatitude: this.endLatitude,
          endLongitude: this.endLongitude
        })

        //this.sidebarOpen = false

        // then after user starts the route --> TODO[]

        const bearing = calculateBearing(geojson.geometry.coordinates[0][0], geojson.geometry.coordinates[0][1], geojson.geometry.coordinates[1][0], geojson.geometry.coordinates[1][1])

        this.mapboxInstance.flyTo({
          center: [this.startLongitude, this.startLatitude],
          bearing: bearing,
          zoom: 18,
          pitch: 125

        })

        // take coordinates for start and the first coordinate n route
        // to calculate the bearing

        // then fly the map to it


      })

    },

    // handle select station as start
    handleSelectStationStart(location) {
      this.selectedStartStation = location
      console.log('coords: handle station start: ', location)
    },

    // handle select station as destination
    handleSelectStationEnd(location) {
      this.selectedEndStation = location
      console.log('coords: handle station end: ', location)

    },

    // handle select coordinates as start
    handleSelectCoordinatesStart(location) {
      this.startLatitude = location[1]
      this.startLongitude = location[0]
      console.log('coords: handle coordinates start: ', location)
    },

    // handle select coordinates as destination
    handleSelectCoordinatesEnd(location) {
      this.endLatitude = location[1]
      this.endLongitude = location[0]
      console.log('coords: handle coordinates end: ', location)
    },
  },
  computed: {
    stations() {
      return this.store.stations
    },
    activeStation() {
      return this.store.activeStation
    },
    directionSearch: {
      get() {
        return this.store.directionSearch
      },
      set(value) {
        this.store.directionSearch = value
      }
    },
    sidebarOpen: {
      get() {
        return this.store.sidebarOpen
      },
      set(value) {
        this.store.sidebarOpen = value
      }
    },
    canDoSearch() {
      // check if all 4 values have been input
      return (
          this.startLatitude !== 0 && this.startLongitude !== 0
          && this.endLongitude !== 0 && this.endLatitude !== 0
      )
    },
    settings() {
      const settingsStore = useSettingsStore()

      return settingsStore.frontendSettings
    }
  }
}
</script>

<style scoped>

</style>