<template>
  <v-layout style="background-color: black;">
    <top-bar></top-bar>
    <search-box />
    <station-info v-if="getDeviceType !== 'mobile'" />
    <map-container v-if="state.mapLoad"></map-container>
  </v-layout>
  <confirm-dialog></confirm-dialog>
  <snackbar-message></snackbar-message>
  <demo-notice></demo-notice>
  <MobileCard />
</template>

<script setup>

import TopBar from "@/components/layout/partials/TopBar";
import MapContainer from "@/components/MapContainer";
import {onBeforeMount, reactive} from "vue";
import {useUiStore} from "@/store/ui-store";
import ConfirmDialog from "@/components/layout/partials/ConfirmDialog";
import SnackbarMessage from "@/components/layout/partials/SnackbarMessage";
import SearchBox from "@/components/SearchBox";
import StationInfo from "@/components/StationInfo";
import DemoNotice from "@/components/ui/DemoNotice";
import MobileCard from "@/components/ui/MobileCard";
import {storeToRefs} from "pinia";

const state = reactive({
  mapLoad: false
})

const { getDeviceType } = storeToRefs(useUiStore())

const { setDeviceTypeFromWidthHeight } = useUiStore()


onBeforeMount(() => {
  setTimeout(() => {
    state.mapLoad = true
    setDeviceTypeFromWidthHeight(1080,1920)
    console.log('setting mapload to true')

  }, 500)
})

</script>

<style scoped>

</style>