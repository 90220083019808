/**
 * This file contains helper functions to reduce needed code for
 * handling various analytics tasks.
 */
// log to analytics
import {getAnalytics, logEvent} from "firebase/analytics";


export function addAnalyticsEvent(eventName, eventParams = {}) {
    try {
        const analytics = getAnalytics()

        logEvent(analytics, eventName, eventParams)
    } catch (e) {
        console.log(e)
    }
}
