<template>
<!--  <ViewPort />-->
  <AppLayout />
</template>

<script setup>
//import ViewPort from "@/components/ViewPort";
import {useSettingsStore} from "@/store/settings-store";
import {useLocationsStore} from "@/store/locations-store";
import AppLayout from "@/components/layout/AppLayout";


const settingsStore = useSettingsStore()

const { loadAll } = settingsStore

/** Load the remote firebase config, remote mapbox settings and backend uri */
loadAll()

/** Load the locations **/

const locationsStore = useLocationsStore()

const { loadLocations } = locationsStore

loadLocations()

</script>

<style>

</style>
