//const prodUrl = 'https://hlada.fotbolti.is/api';
const prodUrl = process.env.VUE_APP_BACKEND_URI;
//const baseUrl = 'http://hlada.test/api';

export async function getMapSettings(baseUri = null) {

    let basePath = baseUri ?? prodUrl

    const response = await fetch(`${basePath}/v1/map-settings`);
    return await response.json();
}

export async function getFrontendSettings(baseUri = null) {

    let basePath = baseUri ?? prodUrl

    const response = await fetch(`${basePath}/v1/frontend-settings`);
    return await response.json();
}

export async function getData(baseUri = null) {
    let basePath = baseUri ?? prodUrl

    try {
        const response = await fetch(`${basePath}/v1/stations`);
        const data = await response.json();
        return data.features;
    } catch (error) {
        console.error(error);
    }
}

export async function searchStation(searchTerm, baseUri = null) {

    let basePath = baseUri ?? prodUrl

    try {
        const response = await fetch(`${basePath}/v1/stations/search?q=${searchTerm}`);
        const data = await response.json();
        return data.features;
    } catch (error) {
        console.error(error);
    }
}

export async function getPaymentProviders(baseUri = null) {
    let basePath = baseUri ?? prodUrl

    const response = await fetch(`${basePath}/v1/payment-info/providers`);
    return await response.json();
}

export async function getNetworks(baseUri = null) {
    let basePath = baseUri ?? prodUrl

    const response = await fetch(`${basePath}/v1/networks`);
    return await response.json();
}

export async function getPlugTypes(baseUri = null) {
    let basePath = baseUri ?? prodUrl

    const response = await fetch(`${basePath}/v1/plug-types`);
    return await response.json();
}