/**
 * This function calculates the bounds for a list of coordinates.
 *
 *
 *      -http://www.movable-type.co.uk/scripts/latlong.html TODO[] javascript function
 *
 */


/**
 *
 * Takes a 2d array of [ [x,y], [x,y] ]
 * @param coordinates
 */
export function getBoundsForCoordinate2d(coordinates) {
    /** set bounds for value **/
    let minLat = 90, maxLat = -90, minLng = 180, maxLng = -180;

    /** Add the coordinates of each location to the coordinates array */
    coordinates.forEach(location => {

        let lat = location[0];
        let lng = location[1];

        if (lat < minLat) minLat = lat;
        if (lat > maxLat) maxLat = lat;
        if (lng < minLng) minLng = lng;
        if (lng > maxLng) maxLng = lng;
    });

    return [[maxLat + 1, minLng], [minLat, maxLng]];
}

export function getBoundsForLocations(locations) {

    /** set bounds for value **/
    let minLat = 90, maxLat = -90, minLng = 180, maxLng = -180;

    /** Add the coordinates of each location to the coordinates array */
    locations.forEach(location => {
        const coordinates = location.geometry.coordinates;

        let lat = coordinates[0];
        let lng = coordinates[1];

        if (lat < minLat) minLat = lat;
        if (lat > maxLat) maxLat = lat;
        if (lng < minLng) minLng = lng;
        if (lng > maxLng) maxLng = lng;
    });

    return [[maxLat + 1, minLng], [minLat, maxLng]];
}


export function fitMapToBounds(map, bounds) {
    console.log('fitMapToBounds', bounds);
    map.fitBounds(bounds, {
        padding: 100,
    });
}

export function fitLocationsToBounds(map, locations) {
    let bounds = getBoundsForLocations(locations);
    fitMapToBounds(map, bounds);
}