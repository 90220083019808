<template>
  <v-app-bar
      id="mainAppBar"
      prominent
      elevation="3"
      color="black"
      style="border-bottom: rgb(30, 213, 95) 2px solid; z-index: 2000;"
  >

    <v-toolbar-title
      :class="{'hidden-sm hidden-xs': search.expanded }"
    >
      <img
        src="https://hlada.fotbolti.is/storage/0U8AZH2FKGMDWvnJQdbOq7CLVhNw0P-metaSGxhw7BhLXdlYmxvZ28ucG5n-.png"
        alt=""
        width="140"
      >
    </v-toolbar-title>

    <v-spacer v-if="!search.expanded"></v-spacer>

    <v-autocomplete
      v-model="search.searchQuery"
      v-model:search="search.selectedLocation"
      @click:prependInner="collapseSearch"
      @click:clear="collapseSearch"
      @click:appendInner="collapseSearch"
      @select="setSelectedLocation"
      @update:modelValue="handleSearchQueryChangeEvent"
      @input="handleSearchQueryChangeEvent"
      @focus="expandSearch"
      @blur="resetSearch && collapseSearch && handleBlur"
      @keydown.escape="collapseSearch"
      :menu-props="{
        closeOnClick: true,
        closeOnContentClick: true,
      }"
      ref="autocompleteSearch"
      @reset="resetSearch && collapseSearch && handleBlur"
      no-filter
      no-data-text="Engar niðurstöður"
      item-title="properties.name"
      item-image="properties.pin"
      placeholder="Leita"
      prepend-inner-icon="mdi-magnify"
      :append-inner-icon="getSearchExpanded ? 'mdi-close' : ''"
      hide-details
      hide-no-data
      dense
      :items="search.searchResults"
      bg-color="black"
      class="m-1 expanding-search border-none"
      color="transparent"
      :class="{ closed: !getSearchExpanded }"
      >

      <template #item="{item}">
        <v-list-item
            @click="setSelectedLocation(item.raw)"
        >
          <v-list-item-content>
              <v-row>
                <v-col cols="1">
                  <v-img
                      :src="item.raw.properties.pin"
                      :alt="item.raw.properties.name"
                      width="40"
                      height="40"
                      contain
                      class="mr-4"
                  ></v-img>
                </v-col>
                <v-col cols="11">
                  <v-list-item-title v-text="item.raw.properties.name"></v-list-item-title>
                  <v-list-item-subtitle v-text="item.raw.properties.address"></v-list-item-subtitle>
                  <v-list-item-subtitle style="color:#111111" v-if="item.raw.properties.city">
                    {{ item.raw.properties.city.postalCode }}, {{ item.raw.properties.city.city }}, {{ item.raw.properties.city.region }}
                  </v-list-item-subtitle>
                </v-col>
              </v-row>
          </v-list-item-content>
        </v-list-item>
      </template>


    </v-autocomplete>

<!--    <v-btn variant="text" icon="mdi-filter"
           @click="toggleFilters"
      :class="{'hidden-sm hidden-xs': search.expanded }"
    ></v-btn>-->

<!--    <v-btn variant="text" icon="mdi-account"
           v-if="!search.expanded"
    ></v-btn>-->
  </v-app-bar>
</template>

<script setup>
  import {storeToRefs} from "pinia";
  import {useUiStore} from "@/store/ui-store";
  import {useLocationsStore} from "@/store/locations-store";

  const { search, getSearchExpanded } = storeToRefs(useUiStore());

  const { expandSearch, collapseSearch, resetSearch, handleSearchQueryChangeEvent } = useUiStore()

  const { setSelectedLocation } = useLocationsStore()

  function handleBlur() {
    console.log('handling blur')
    document.activeElement.blur()
  }

  //console.log(search)
</script>

<style scoped >
  /*
  .v-field__outline
    ::before, ::after
      border-style: none !important

  .v-input.expanding-search
    transition: max-width 5s
    .v-field
      .v-field__prepend-inner
        i.v-icon
          opacity: 100% !important
      .v-input__slot
        &:before, &:after
          border-bottom-color: transparent !important
          color: #ffffff !important
    .v-field__outline
      &:before, &:after
        color: #ffffff !important
        border: none !important
        border-bottom-color: transparent !important
    &.closed
      max-width: 45px
  */

  .



    .v-input__control > .v-field--variant-filled > .v-field__outline::before {
      border-style: none !important;
    }

  .expanding-search {
    color: #ffffff !important;
    opacity: 100% !important;
    border-bottom-color: transparent !important;
    transition: max-width 2s ease-in-out;
  }

  .expanding-search.closed {
    max-width: 45px;
  }

  .expanding-search i {
    opacity: 100% !important;
  }

/*  .v-input.expanding-search
    transition: max-width 2s ease-in-out
    .v-field
      .v-field__prepend-inner
        i.v-icon
          opacity: 100% !important
      .v-input__slot
        &:before, &:after
          border-bottom-color: transparent !important
          color: #ffffff !important
    .v-field_outline, .v-input__slot
      &:before, &:after
        color: #ffffff !important
        border-bottom-color: transparent !important
    &.closed
      max-width: 45px

  .v-field__prepend-inner
    .v-icon
      opacity: 100% !important

  .v-input.expanding-search
    .v-input__slot
      &:before, &:after
        border-bottom-color: transparent !important
        color: #ffffff !important*/
</style>