<template>
  <model-select
      :options="optionsArray"
      placeholder="Veldu staðsetningu"
      v-model="selectedId"
  >

  </model-select>

</template>

<script>
import "vue-search-select/dist/VueSearchSelect.css"


import {ModelSelect} from "vue-search-select";
export default {
  name: "SearchableDropdown",
  components: {
    ModelSelect
  },
  data() {
    return {
      selectedId: null,
      location: null
    }
  },
  created() {
    if (this.selectedId) {
      this.selectedId = this.selected
    }
  },
  props: {
    options: Array
  },
  computed: {
    optionsArray() {
      return this.options.map(function(option) {
        return {
          value: option.properties.id,
          text: option.properties.name,
        }
      })
    }
  },
  watch: {
    selectedId(id) {
      console.log('given id:', id)

      const location = this.options.find(location => location.properties.id === id)

      console.log('selected id => location', location)

      // get location
      this.$emit('selectedId', location.geometry.coordinates)
    },
    location(location) {
      console.log('location changed: ', location)
      //this.selectedId = location.properties.id
    }
  }
}
</script>

<style scoped>

</style>