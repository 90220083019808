<template>
  <div id="mobile-card" class="hidden-lg-and-up d-lg-none d-flex flex-column h-100">
    <div class="header" ref="cardHeader">
      <img class="network-logo" :src="networkLogo" alt="Network Logo" />
      <div class="station-name-wrapper">
        <h4 class="station-name">{{ getSelectedLocation.properties.name }}</h4>
      </div>
      <div class="close-btn" @click="resetSelectedLocation">x</div>
    </div>

    <div class="body flex-grow-1 overflow-auto">
      <div>
        <strong>
          {{ getSelectedLocation.properties.brand_name }}
        </strong>
      </div>
      <div class="location-info">
        <p>{{ getSelectedLocation.properties.address }}</p>
        <p>{{ getSelectedLocation.properties.city.city }}, {{ getSelectedLocation.properties.city.postalCode }}</p>
        <p>{{ getSelectedLocation.properties.city.type }}, {{ getSelectedLocation.properties.city.region }}</p>
      </div>
      <div class="charging-points">
        <!-- Charging points info here -->
        <connection-type />
      </div>
      <div class="payment-info">
        <payment-provider dark />

        <a :href="googlePlayLink">
          <img src="https://demo.hlada.is/misc/appstore.png" class="payment-logo" alt="Get it on Google Play" />
        </a>
        <a :href="appStoreLink">
          <img src="https://demo.hlada.is/misc/appstore.png" class="payment-logo" alt="Download on the App Store" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import {storeToRefs} from "pinia";
import {useLocationsStore} from "@/store/locations-store";
import PaymentProvider from "@/components/PaymentProvider";
import ConnectionType from "@/components/ConnectionType";

export default {
  components: {ConnectionType, PaymentProvider},
  props() {
    return {
      station: {
        type: Object
      }
    }
  },
  setup() {
    const networkLogo = ref('https://hlada.fotbolti.is/storage/brands/on_125x200.jpeg');
    const stationName = ref('Test hleðslustöð');
    const address = ref('Mánagata 2');
    const cityName = ref('Ísafjörður');
    const postalCode = ref('400');
    const municipality = ref('Vestfirðir');
    const googlePlayLink = ref('');
    const appStoreLink = ref('');

    const cardHeader = ref(null);

    const onTouchStart = (event) => {
      const startY = event.touches[0].clientY;

      const onTouchMove = (event) => {
        const deltaY = event.touches[0].clientY - startY;
        const card = document.getElementById('mobile-card');
        const cardTop = card.offsetTop + deltaY;
        const appBarHeight = document.getElementById('mainAppBar').offsetHeight;

        if (deltaY < 0 && cardTop >= appBarHeight) {
          card.style.top = `${cardTop}px`;
        } else if (deltaY > 0 && cardTop + card.offsetHeight <= window.innerHeight && cardTop <= window.innerHeight * 0.3 - appBarHeight) {
          card.style.top = `${cardTop}px`;
        }
      };




      const onTouchEnd = () => {
        cardHeader.value.removeEventListener('touchmove', onTouchMove);
        cardHeader.value.removeEventListener('touchend', onTouchEnd);
      };

      cardHeader.value.addEventListener('touchmove', onTouchMove);
      cardHeader.value.addEventListener('touchend', onTouchEnd);
    };

    onMounted(() => {
      const card = document.getElementById('mobile-card');
      card.style.top = `${window.innerHeight * 0.5}px`;

      cardHeader.value.addEventListener('touchstart', onTouchStart);
    });

    onUnmounted(() => {
      cardHeader.value.removeEventListener('touchstart', onTouchStart);
    });

    const { getSelectedLocation } = storeToRefs(useLocationsStore());

    const { resetSelectedLocation } = useLocationsStore()

    return {
      networkLogo,
      stationName,
      address,
      cityName,
      postalCode,
      municipality,
      googlePlayLink,
      appStoreLink,
      cardHeader,
      getSelectedLocation,
      resetSelectedLocation
    };
  },
};
</script>

<style scoped>
.close-btn {
  background: none;
  border: 1px solid #ffffff;
  border-radius: 50%;
  color: #ffffff;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0.15rem 0.35rem;
  line-height: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

#mobile-card {
  top: 60%;
  width: 100%;
  height: 50%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #212529;
  color: #ffffff;
}

.header {
  z-index: 1000;
  background-color: #1a1a1a;
  padding: 0.3rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #333333;
  touch-action: none;
  user-select: none;
}

.station-name-wrapper {
  flex-grow: 1;
  margin-left: 1rem;
  overflow: hidden;
}

.station-name {
  margin: 0;
  font-size: 1.1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.network-logo {
  width: 58px;
  height: 47px;
}

.station-name {
  margin: 0;
  font-size: 1.1rem;
}

.body {
  z-index: 10000;
  padding: 1rem;
  background-color: #212529;
  overflow-y: auto;
  flex-grow: 1;
}

.payment-logo {
  max-width: 200px;
}

</style>
