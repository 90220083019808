/**
 *
 *
 */
import {defineStore} from "pinia";
import {getData} from "@/lib/api";
import {useSettingsStore} from "@/store/settings-store";
import {useMapStore} from "@/store/map-store";
import {useUiStore} from "@/store/ui-store";
//import {fitLocationsToBounds} from "@/lib/map";

export const useLocationsStore = defineStore('locations', {
    state: () => ({
        /** List of all locations **/
        locations: [

        ],
        /** List of layers **/
        layers: [

        ],
        /** List of networks **/
        networks: [

        ],
        /** List of plug types **/
        plugTypes:[

        ],

        /** Holds the object for the currently selected location **/
        selectedLocation: null
    }),
    actions: {
        setLocations(locations) {

            console.log('setting locations', locations)

            if (locations === null || locations === undefined) {
                console.log('locations is null or undefined')
                return
            }

            if (!('length' in locations)) {
                console.log('locations has no length property')
                return
            }

            this.locations = locations

            const mapStore = useMapStore()

            const { fitLocationsToBounds } = mapStore

            locations.length > 0 && fitLocationsToBounds(locations)

            // setTimeout(() => {
            //     const { startCameraRotation } = useMapStore()
            //
            //     startCameraRotation(0)
            // }, 1500)
        },
        resetSelectedLocation() {
            /** stop camera rotation *
             */
            const { stopCameraRotation, informResize, resetMapCamera } = useMapStore()

            stopCameraRotation()

            /** close the sidebar */
            const { setSidebarClosed } = useUiStore()
            setSidebarClosed()


            this.selectedLocation = null

            informResize()

            setTimeout(() => {
                console.log('Now should reset map camera')
                resetMapCamera()
            }, 500)

        },
        setSelectedLocation(location = null) {
            /** reset */
            /** stop camera rotation *
             */
            const { stopCameraRotation, informResize } = useMapStore()

            stopCameraRotation()

            /** close the sidebar */
            const { setSidebarClosed } = useUiStore()
            setSidebarClosed()


            this.selectedLocation = null

            informResize()

            const { setSidebarOpen } = useUiStore()

            // open the sidebar
            setSidebarOpen()

            // collapse search
/*            const { collapseSearch } = useUiStore()
            collapseSearch()*/

            const setLocation = location

            if (setLocation) {
                /** Hide the search results **/
                //const { hideSearchResults } = useUiStore()
                //hideSearchResults()


                /** Setting location **/
                this.selectedLocation = setLocation

                const { flyToCoordinates } = useMapStore()

                setTimeout(() => {
                    /** Fly to location */
                    flyToCoordinates(setLocation.geometry.coordinates)
                }, 500)


                setTimeout(() => {
                    const { startCameraRotation } = useMapStore()

                    startCameraRotation(0)
                }, 1700)

            } else {
                console.log('setting location to null')
                this.selectedLocation = null

                /** Center map on all coordinates **/

                const { fitLocationsToBounds } = useMapStore()
                console.log('fitting locations to bounds next')
                fitLocationsToBounds(this.locations)
            }

            informResize()
        },
        setSelectedLocationById(locationId) {
            const location = this.getLocationById(locationId)



            if (location) {
                this.setSelectedLocation(location)


            }
        },
        loadLocations() {
            /**
             * Loads the remote locations into the store
             */

            console.log('loading locations')

            const settingsStore = useSettingsStore()

            const { backendUri } = settingsStore



            getData(backendUri)
                .then((data) => {
                    const locations = Object.values(data)

                    this.setLocations(locations)
                })
        }
    },
    getters: {
        getSelectedLocation: (state) => state.selectedLocation,
        getLocationById: (state) => (id) => state.location.find((location) => location.properties.id === id),
        getAllLocations: (state) => state.locations,
        getPinnedLocations: (state) => {
          if (state.selectedLocation) {
            return state.locations.filter((location) => location.properties.id === state.selectedLocation.properties.id)
          }

          return state.locations
        },
        getFilteredStations: (state) => state.locations
        /**
         *      Old logic for filtered stations with brand and network keys
         *          return this.stations.filter((station) => {
         *           return Object.values(this.store.filters.networks).includes(station.properties.brand_key) ||
         *               Object.values(this.store.filters.networks).includes(station.properties.network)
         *         })
         *
         */
    }
})