<template>
  <div class="w-full">
    <input type="text" v-model="state.searchTerm" :placeholder="placeholders[0]" @input="debounceSearchApi">

    <aside>
      <ul>
        <li v-for="result in state.results" :key="result.id" @click="handleSelectCoordinates(result.geometry.coordinates)">
          {{ result.place_name }}
          {{ result.place_type[0] }}
          {{ result.geometry }}
        </li>
      </ul>
    </aside>
  </div>
  <v-row justify="center">
    <v-dialog
        v-model="state.dialog"
        persistent
        width="1024"
    >
      <template v-slot:activator="{ props }">
        <v-btn
            color="primary"
            v-bind="props"
        >
          Nýtt Test
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">User Profile</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-text-field
                    label="Legal first name*"
                    required
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-text-field
                    label="Legal middle name"
                    hint="example of helper text only on focus"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-text-field
                    label="Legal last name*"
                    hint="example of persistent helper text"
                    persistent-hint
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    label="Email*"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    label="Password*"
                    type="password"
                    required
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
              >
                <v-select
                    :items="['0-17', '18-29', '30-54', '54+']"
                    label="Age*"
                    required
                ></v-select>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
              >
                <v-autocomplete
                    :items="['Skiing', 'Ice hockey', 'Soccer', 'Basketball', 'Hockey', 'Reading', 'Writing', 'Coding', 'Basejump']"
                    label="Interests"
                    multiple
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue-darken-1"
              variant="text"
              @click="state.dialog = false"
          >
            Close
          </v-btn>
          <v-btn
              color="blue-darken-1"
              variant="text"
              @click="state.dialog = false"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script setup>
const placeholders = [
  'Leitaðu af staðsetningu...',
  'Leitaðu af götuheiti...',
  'Leitaðu af bæjarfélagi...'
]

import {onMounted, reactive, ref, defineEmits} from 'vue';

const emit = defineEmits(['selectedCoordinates']);

const handleSelectCoordinates = (coordinates) => {
  emit('selectedCoordinates', coordinates);

  // reset the results
  state.results = [];

  state.hasSelected = true
}

const state = reactive({
  searchTerm: '',
  dialog: false,
  results: [

  ],
  isLoading: false,
  errorMessage: '',
  hasSelected: false,
});

const debounceTimeout = ref(null);

async function searchApi(searchTerm) {
  state.isLoading = true;
  try {
    const response = await fetch(`https://directions.hlada.is/api/v1/search?query=${searchTerm}`);
    const data = await response.json();

    state.results = data.results;
    state.errorMessage = '';
  } catch (error) {
    state.results = [];
    state.errorMessage = error.message;
  } finally {
    state.isLoading = false;
  }
}

onMounted(() => {
  //console.log('mounted ww');
});

function debounceSearchApi() {
  clearTimeout(debounceTimeout.value);
  debounceTimeout.value = setTimeout(() => {
    searchApi(state.searchTerm);
  }, 500);
}

// const search = (term) => {
//   console.log('searching...', term)
// }

</script>

<style scoped>

</style>