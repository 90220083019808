<template>
  <v-card
      v-if="storeFilters.open"
      class="mx-auto"
      max-width="400"
  >
    <v-toolbar
        flat
        color="deep-purple-accent-4"
        dark
    >
      <v-btn icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Filter results</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <h2 class="text-h6 mb-2">
        Veldu hleðslunet
      </h2>

      <v-chip-group
          v-model="amenities"
          column
          multiple
      >
        <v-chip
            v-for="network in networks"
            :key="network.key"
            filter
            variant="outlined"
        >
          <img class="b-avatar" :src="getImageForNetwork(network.key)">
          {{ network.name }}
        </v-chip>

      </v-chip-group>
    </v-card-text>

    <v-card-text>
      <h2 class="text-h6 mb-2">
        Choose neighborhoods
      </h2>

      <v-chip-group
          v-model="neighborhoods"
          column
          multiple
      >
        <v-chip
            filter
            variant="outlined"
        >
          Snowy Rock Place
        </v-chip>
        <v-chip
            filter
            variant="outlined"
        >
          Honeylane Circle
        </v-chip>
        <v-chip
            filter
            variant="outlined"
        >
          Donna Drive
        </v-chip>
        <v-chip
            filter
            variant="outlined"
        >
          Elaine Street
        </v-chip>
        <v-chip
            filter
            variant="outlined"
        >
          Court Street
        </v-chip>
        <v-chip
            filter
            variant="outlined"
        >
          Kennedy Park
        </v-chip>
      </v-chip-group>
    </v-card-text>
  </v-card>


  <div class="network-filter d-none">
    <div class="vertical-grid">


      <v-tooltip
          v-if="!expanded.networks"
          location="left"
      >
        <template v-slot:activator="{ props }">
          <v-img
              icon
              v-bind="props"
          >
            <img
                :src="settings.networkFilterExpandedIcon"
                :alt="settings.openNetworkFiltersHelpText"
                :title="settings.openNetworkFiltersHelpText"
                @click="toggleExpandNetworks"
            >
          </v-img>
        </template>
        <span>{{ settings.openNetworkFiltersHelpText }}</span>
      </v-tooltip>


      <template v-if="expanded.networks">



        <v-tooltip
            location="left"
        >
          <template v-slot:activator="{ props }">
            <v-img
                icon
                v-bind="props"
            >
              <img
                  :src="settings.networkFilterCollapsedIcon"
                  :alt="settings.closeNetworkFiltersHelpText"
                  :title="settings.closeNetworkFiltersHelpText"
                  @click="toggleExpandNetworks"
              >
            </v-img>
          </template>
          <span>{{ settings.closeNetworkFiltersHelpText }}</span>
        </v-tooltip>


        <v-tooltip
          v-for="network in networks"
          :key="network.key"
          location="left"
        >
          <template
              v-slot:activator="{ props }"
              v-if="! network.hide_filter && (network.pin_to_filter || showMore.networks)"
          >
              <img
                  v-bind="props"
                  @click="toggleNetwork(network.key)"
                  :src="getImageForNetwork(network.key)"
                  :alt="(isNetworkActive(network.key) ? settings.hideNetworkFilterHelpText : settings.showNetworkFilterHelpText).replace('{network}', network.name)"
                  :title="(isNetworkActive(network.key) ? settings.hideNetworkFilterHelpText : settings.showNetworkFilterHelpText).replace('{network}', network.name)"
              >
          </template>
          <span>{{ (isNetworkActive(network.key) ? settings.hideNetworkFilterHelpText : settings.showNetworkFilterHelpText).replace('{network}', network.name) }}</span>
        </v-tooltip>




        <v-tooltip
            v-if="hasMoreNetworks && ! showMore.networks"
            location="left"
        >
          <template v-slot:activator="{ props }">
            <v-img
                icon
                v-bind="props"
            >
              <img
                  :src="settings.showMoreNetworksCollapsedIcon"
                  @click="toggleShowMoreNetworks"
                  :alt="settings.showMoreNetworkFiltersHelpText"
                  :title="settings.showMoreNetworkFiltersHelpText"
              >
            </v-img>
          </template>
          <span>{{ settings.showMoreNetworkFiltersHelpText }}</span>
        </v-tooltip>


        <v-tooltip
            v-if="hasMoreNetworks && showMore.networks"
            location="left"
        >
          <template v-slot:activator="{ props }">
            <v-img
                icon
                v-bind="props"
            >
              <img
                  :src="settings.showMoreNetworksExpandedIcon"
                  @click="toggleShowMoreNetworks"
                  :alt="settings.showLessNetworkFiltersHelpText"
                  :title="settings.showLessNetworkFiltersHelpText"
              >
            </v-img>
          </template>
          <span>{{ settings.showLessNetworkFiltersHelpText }}</span>
        </v-tooltip>



      </template>

      <v-tooltip
          v-if="!expanded.plugs"
          location="left"
      >
        <template v-slot:activator="{ props }">
          <v-img
              icon
              v-bind="props"
          >
            <img
                :src="settings.plugTypeFilterExpandedIcon"
                :alt="settings.openPlugTypeFiltersHelpText"
                :title="settings.openPlugTypeFiltersHelpText"
                @click="toggleExpandPlugs"
            >
          </v-img>
        </template>
        <span>{{ settings.openPlugTypeFiltersHelpText }}</span>
      </v-tooltip>


      <template v-if="expanded.plugs">

        <v-tooltip
            location="left"
        >
          <template v-slot:activator="{ props }">
            <v-img
                icon
                v-bind="props"
            >
              <img
                  :src="settings.plugTypeFilterCollapsedIcon"
                  :alt="settings.closePlugTypeFiltersHelpText"
                  :title="settings.closePlugTypeFiltersHelpText"
                  @click="toggleExpandPlugs"
              >
            </v-img>
          </template>
          <span>{{ settings.closePlugTypeFiltersHelpText }}</span>
        </v-tooltip>


        <v-tooltip
            v-for="plugType in plugTypes"
            :key="plugType.key"
            location="left"
        >
          <template v-slot:activator="{ props }">
            <v-img
                icon
                v-bind="props"
            >
              <img
                  @click="togglePlugType(plugType.key)"
                  :src="getImageForPlugType(plugType.key)"
                  :alt="(isPlugTypeActive(plugType.key) ? settings.hidePlugTypeFilterHelpText : settings.showPlugTypeFilterHelpText).replace('{plugType}', plugType.name)"
                  :title="(isPlugTypeActive(plugType.key) ? settings.hidePlugTypeFilterHelpText : settings.showPlugTypeFilterHelpText).replace('{plugType}', plugType.name)"
              >
            </v-img>
          </template>
          <span>{{ (isPlugTypeActive(plugType.key) ? settings.hidePlugTypeFilterHelpText : settings.showPlugTypeFilterHelpText).replace('{plugType}', plugType.name) }}</span>
        </v-tooltip>



      </template>


      <!-- driving mode -->
<!--      <v-tooltip
          v-if="settings.drivingModeEnabled && drivingMode"
          location="left"
      >
        <template v-slot:activator="{ props }">
          <v-img
              icon
              v-bind="props"
          >
            <img
                @click="toggleDrivingMode"
                :src="settings.drivingModeEnabledIcon"
                :title="settings.drivingModeDisableIconHelpText"
                :alt="settings.drivingModeDisableIconHelpText"
            >
          </v-img>
        </template>
        <span>{{ settings.drivingModeDisableIconHelpText }}</span>
      </v-tooltip>

      <v-tooltip
          v-if="settings.drivingModeEnabled && !drivingMode"
          location="left"
      >
        <template v-slot:activator="{ props }">
          <v-img
              icon
              v-bind="props"
          >
            <img
                @click="toggleDrivingMode"
                :src="settings.drivingModeDisabledIcon"
                :title="settings.drivingModeEnableIconHelpText"
                :alt="settings.drivingModeEnableIconHelpText"
            >
          </v-img>
        </template>
        <span>{{ settings.drivingModeEnableIconHelpText }}</span>
      </v-tooltip>-->




      <v-tooltip
          v-if="getIsCurrentlyTracking"
          location="left"
      >
        <template v-slot:activator="{ props }">
          <v-img
              icon
              v-bind="props"
          >
            <img v-if="getIsCurrentlyTracking" @click="pauseUserTracking" :src="settings.userLocationFilterDisabledIcon" alt="">
          </v-img>
        </template>
        <span>{{ settings.userLocationDisableIconHelpText }}</span>
      </v-tooltip>

      <v-tooltip
          v-if="!getIsCurrentlyTracking"
          location="left"
      >
        <template v-slot:activator="{ props }">
          <v-img
              icon
              v-bind="props"
          >
            <img @click="startUserTracking" :src="settings.userLocationFilterEnabledIcon" alt="">
          </v-img>
        </template>
        <span>{{ settings.userLocationEnableIconHelpText }}</span>
      </v-tooltip>


    </div>
  </div>

</template>

<script>
import {useStore} from "@/main";
import {getBoundsForLocations} from "@/lib/map";
import {getAnalytics, logEvent} from "firebase/analytics";
import { calculateBearing } from "@/lib/math";
import {useSettingsStore} from "@/store/settings-store";
import {useUserLocationStore} from "@/store/user-location-store";
import {storeToRefs} from "pinia";
import {useUiStore} from "@/store/ui-store";

export default {
  name: "NetworkFilter",
  setup() {

    const { filters } = storeToRefs(useUiStore())

    const { getIsCurrentlyTracking } = storeToRefs(useUserLocationStore())

    const { startUserTracking, pauseUserTracking } = useUserLocationStore()

    return { getIsCurrentlyTracking, startUserTracking, pauseUserTracking, storeFilters: filters }
  },
  data() {
    return {
      expanded: {
        networks: false,
        plugs: false
      },
      showMore: {
        networks: false,
        plugs: false
      },
      amenities: [1, 4],
      neighborhoods: [1],
    }
  },
  methods: {
    toggleUserLocation() {
      this.store.userLocationEnabled = !this.store.userLocationEnabled;

      if (this.store.userLocationEnabled) {
        this.store.mapboxInstance.flyTo({
          pitch: 80
        })

        // watch user location
        this.store.userLocationWatchId = navigator.geolocation.watchPosition((position) => {

          let oldLocation = this.store.userLocation

          this.store.userLocation = [position.coords.longitude, position.coords.latitude]
          //this.store.mapCenter = [position.coords.longitude, position.coords.latitude]

          let bearing = calculateBearing(oldLocation[1], oldLocation[0], position.coords.latitude, position.coords.longitude)

          //console.log('setting bearing', bearing)

          //this.userBearing = bearing

          this.store.mapboxInstance.flyTo({
            center: [position.coords.longitude, position.coords.latitude],
            animate: true,
            duration: 350,
            zoom: 18,
            pitch: 125,
            bearing: bearing
          })

          // user location added
          //this.addUserLocation([position.coords.longitude, position.coords.latitude])

          console.log('user location', position)
        }, (error) => {
          console.log(error)
        }, {
          padding: 20,
          animate: true,
          enableHighAccuracy: true,
          timeout: 550,
        });

        // try {
        //   const analytics = getAnalytics()
        //
        //   // Tell analytics that the user clicked on a station
        //   logEvent(analytics, 'user_location_was_enabled', {
        //   })
        // } catch (e) {
        //   console.log(e)
        // }
      }

      if (! this.store.userLocationEnabled) {
        // location was disabled
        if (this.drivingMode) {
          // turn off driving mode
          this.toggleDrivingMode()
        }

        // remove location watching
        navigator.geolocation.clearWatch(this.store.userLocationWatchId)

        let bounds = getBoundsForLocations(this.store.stations)

        this.store.mapboxInstance.fitBounds(bounds, {
          padding: 20,
          animate: true,
          duration: 1700,
          pitch: 20
        })

        // try {
        //   const analytics = getAnalytics()
        //
        //   // Tell analytics that the user clicked on a station
        //   logEvent(analytics, 'user_location_was_disabled', {
        //   })
        // } catch (e) {
        //   console.log(e)
        // }
      }

    },
    toggleDrivingMode() {
      // Change the driving mode toggle
      this.store.drivingMode = !this.store.drivingMode;

      // If we are going to driving mode, we need to ask the user for gps coordinates

      if (this.drivingMode) {
        if (! this.userLocationEnabled) {
          this.toggleUserLocation(); // enable user location
        }
      }

      if (! this.drivingMode) {

        let bounds = getBoundsForLocations(this.store.stations)

        this.store.mapboxInstance.fitBounds(bounds, {
          padding: 20,
          animate: true,
          duration: 1700,
          pitch: 20
        })

        // get the bounds
      }

    },
    toggleShowMoreNetworks() {
      this.showMore.networks = !this.showMore.networks

      // try {
      //   const analytics = getAnalytics()
      //
      //   // Tell analytics that the user clicked on a station
      //   logEvent(analytics, 'user_show_more_networks', {
      //   })
      // } catch (e) {
      //   console.log(e)
      // }
    },
    toggleShowMorePlugTypes() {
      this.showMore.plugs = !this.showMore.plugs

      // try {
      //   const analytics = getAnalytics()
      //
      //   // Tell analytics that the user clicked on a station
      //   logEvent(analytics, 'user_show_more_plugs', {
      //   })
      // } catch (e) {
      //   console.log(e)
      // }
    },
    toggleExpandNetworks() {
      this.expanded.networks = !this.expanded.networks

     //  try {
     //   const analytics = getAnalytics()
     //
     //   // Tell analytics that the user clicked on a station
     //   logEvent(analytics, 'user_toggle_expand_networks', {
     //   })
     // } catch (e) {
     //   console.log(e)
     // }


    },
    toggleExpandPlugs() {
      this.expanded.plugs = !this.expanded.plugs

      // try {
      //   const analytics = getAnalytics()
      //
      //   // Tell analytics that the user clicked on a station
      //   logEvent(analytics, 'user_toggle_expand_plugs', {
      //   })
      // } catch (e) {
      //   console.log(e)
      // }
    },
    getImageForPlugType(key) {
      const plugType = this.getPlugTypeByKey(key)

      /** check if network key is in filters */
      const index = this.filters.plugTypes.indexOf(key)
      if (index > -1) {
        return plugType.filter_image_on
      } else {
        return plugType.filter_image_off
      }
    },
    getPlugTypeByKey(key) {
      return this.plugTypes.find(plugtype => plugtype.key === key)
    },
    togglePlugType(key) {

      //let activated;

      const index = this.filters.plugTypes.indexOf(key)
      if (index > -1) {
        this.filters.plugTypes.splice(index, 1)
        // plug type was deactivated
        //activated = false;
      } else {
        this.filters.plugTypes.push(key)
        // plug type was activated
        //activated = true;
      }

      // try {
      //   const analytics = getAnalytics()
      //
      //   // Tell analytics that the user clicked on a station
      //   logEvent(analytics, 'user_toggle_plugType', {
      //     wasActivated: activated
      //   })
      // } catch (e) {
      //   console.log(e)
      // }
    },
    getNetworkByKey(key) {
      return this.networks.find(network => network.key === key)
    },
    getImageForNetwork(key) {
      const network = this.getNetworkByKey(key)

      /** check if network key is in filters */
      const index = this.filters.networks.indexOf(key)
      if (index > -1) {
        return network.filter_image_on
      } else {
        return network.filter_image_off
      }
    },
    toggleNetwork(key) {
      /** Check if brand_key is in filters.networks, if it is then delete it, if not then add it */
      const index = this.filters.networks.indexOf(key)

      let activated;

      if (index > -1) {
        this.filters.networks.splice(index, 1)
        activated = false
      } else {
        this.filters.networks.push(key)
        activated = true
      }

      try {
        const analytics = getAnalytics()

        // Tell analytics that the user clicked on a station
        logEvent(analytics, 'user_toggle_plugType', {
          wasActivated: activated
        })
      } catch (e) {
        console.log(e)
      }
    },
    isNetworkActive(key) {
      return this.filters.networks.indexOf(key) > -1
    },
    isPlugTypeActive(key) {
      return this.filters.plugTypes.indexOf(key) > -1
    }
  },
  created() {
    this.store = useStore()

    //console.log('created network filter component', Object.values(this.store.networks))


  },
  mounted() {

    setTimeout(() => {
      // set filter expanded settings
      this.expanded.networks = this.settings.networkFilterExpanded
      this.expanded.plugs = this.settings.plugTypeFilterExpanded



    }, 1000)
  },
  computed: {
    userBearing: {
      get() {
        return this.store.userBearing
      },
      set(value) {
        this.store.userBearing = value
      }
    },
    mapboxInstance() {
      return this.store.mapboxInstance
    },
    drivingMode: {
      get() {
        return this.store.drivingMode
      },
      set(value) {
        this.store.drivingMode = value
      }
    },
    userLocationEnabled() {
      return this.store.userLocationEnabled
    },
    hasMoreNetworks() {
      // TODO[] implement
      return Object.values(this.networks)
          .filter(network => ! network.hide_filter && ! network.pin_to_filter)
          .length > 0
    },
    hasMorePlugTypes() {
      // TODO[] implement
      return Object.values(this.plugTypes)
          .filter(plugType => ! plugType.hide_filter && ! plugType.pin_to_filter)
          .length > 0
    },
    settings() {
      const settingsStore = useSettingsStore()

      return settingsStore.frontendSettings
    },
    networks() {
      return Object.values(this.store.networks).map(network => {
        return {
          ...network,
          active: Object.values(this.filters.networks).includes(network.key)
        }
      })
    },
    plugTypes() {
      return Object.values(this.store.plugTypes).map(plugType => {
        return {
          ...plugType,
          active: Object.values(this.filters.plugTypes).includes(plugType.key)
        }
      })
    },
    filters() {
      return this.store.filters
    }
  }
}
</script>

<style scoped>
 .network-filter {
   top:8rem;
   right:0;
   position: fixed;
 }

 .vertical-grid {
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: repeat(6, 1fr);
   grid-gap: 0.1rem;
 }
</style>