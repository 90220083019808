<template>
  <v-row justify="center">
    <v-dialog
        v-model="state.dialog"
        persistent
        width="80%"
        height="90%"
    >
      <template v-if="1 == 2" v-slot:activator="{ props }">
        <v-btn
            color="primary"
            v-bind="props"
        >
          Opna Demo Dialog
        </v-btn>
      </template>
      <v-card height="100%" style="background-color: rgb(30, 213, 95);">
        <v-card-title class="text-center">
          <h2 class="text-h4 font-weight-black text-black">PRUFUÚTGÁFA!</h2>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <p class="text-body-2">
                  Þessi útgáfa er <strong>DEMO</strong> og getur innihaldið brotna fítusa.
                </p>

              </v-col>

            </v-row>
            <v-row>
              <v-sheet
                  class="d-flex align-center justify-center flex-wrap text-center demo-notice mx-auto"
                  elevation="4"
                  rounded
                  max-width="800"
                  width="90%"
              >

                <div class="text-h5 font-weight-medium mb-2">
                  DEMO v.0.0.4
                </div>

                <p class="text-body-2 mb-4">
                  Þessi útgáfa er <strong>DEMO</strong> og gæti innihaldið brotna virkni.<br>
                  **  **

                  <br>
                  <strong>Stærri uppfærsla á UI, nýr AppBar</strong>
                  <br>
                  <strong>FPS teljari og GPS debug bætt við!</strong>
                  <br>
                  <strong>Ný tool-tips við hover-on á filtera</strong>

                </p>

              </v-sheet>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="#fff"
              style="outline-color: #eeeeee; outline-width: 3px; outline-style: solid;"
              size="large"
              variant="text"
              @click="state.dialog = false"
          >
            Opna <strong>DEMO ÚTGÁFU</strong>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script setup>

import { ref } from 'vue'

const state = ref({
  dialog: false,
})


</script>

<style scoped>
 .demo-notice {
   position: absolute;
   z-index: 1000;
 }
</style>