<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog.show"
        :persistent="dialog.persistent"
        width="auto"
    >
<!--      <template v-slot:activator="{ props }">-->
<!--        <v-btn-->
<!--            color="primary"-->
<!--            v-bind="props"-->
<!--        >-->
<!--          Open Dialog-->
<!--        </v-btn>-->
<!--      </template>-->
      <v-card>
        <v-card-title class="text-h5">
          {{ dialog.title }}
        </v-card-title>
        <v-card-text>{{dialog.text}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="green-darken-1"
              variant="text"
              @click="hideRunCb(dialog.callback.deny)"
          >
            {{dialog.denyText}}
          </v-btn>
          <v-btn
              color="green-darken-1"
              variant="text"
              @click="hideRunCb(dialog.callback.accept)"
          >
            {{dialog.acceptText}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script setup>

import {storeToRefs} from "pinia";
import {useUiStore} from "@/store/ui-store";

const { dialog } = storeToRefs(useUiStore())
const { resetConfirmDialog } = useUiStore()

function hideRunCb(callback) {
  console.log('hide run callback')
  resetConfirmDialog()
  callback()
}

</script>

<style scoped>

</style>