<template>
  <div class="">
    <h4 class="sub-heading">
      <img height="80" width="80" :src="'https://hlada.fotbolti.is/'+station.properties.brand_image" class="img-fluid p-1" alt="Responsive image">
    </h4>
  </div>
</template>

<script>
export default {
  name: "NetworkInfo",
  props: {
    station: Object,
  }
}
</script>

<style scoped>
  h4.sub-heading {
    padding: 0.1rem -3rem 0.1rem 0;
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
</style>