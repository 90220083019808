<template>
  <v-timeline side="end" align="start">

    <v-timeline-item
        dot-color="pink"
        size="small"
    >
      <div class="d-flex">
        <strong class="me-4">5pm</strong>
        <div>
          <strong>New Icon</strong>
          <div class="text-caption">
            Mobile App
          </div>
        </div>
      </div>
    </v-timeline-item>

    <v-timeline-item
        dot-color="teal-lighten-3"
        size="small"
    >
      <div class="d-flex">
        <strong class="me-4">3-4pm</strong>
        <div>
          <strong>Design Stand Up</strong>
          <div class="text-caption mb-2">
            Hangouts
          </div>
        </div>
      </div>
    </v-timeline-item>

    <v-timeline-item
        dot-color="pink"
        size="small"
    >
      <div class="d-flex">
        <strong class="me-4">12pm</strong>
        <div>
          <strong>Lunch break</strong>
        </div>
      </div>
    </v-timeline-item>

    <v-timeline-item
        dot-color="teal-lighten-3"
        size="small"
    >
      <div class="d-flex">
        <strong class="me-4">9-11am</strong>
        <div>
          <strong>Finish Home Screen</strong>
          <div class="text-caption">
            Web App
          </div>
        </div>
      </div>
    </v-timeline-item>

  </v-timeline>
</template>

<script setup>

import {useDrivingStore} from "@/store/driving-mode-store";
import {storeToRefs} from "pinia";

const { legs } = storeToRefs(useDrivingStore())

console.log(legs)

</script>

<style scoped>

</style>