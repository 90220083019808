<template>
  <div class="text-center">
<!--    <v-btn
        color="orange-darken-2"
        @click="snackbar = true"
    >
      Open Snackbar
    </v-btn>-->

    <v-snackbar
        v-model="messageBox.show"
        :timeout="messageBox.timeout"
    >
      {{ messageBox.text }}

      <template v-slot:actions>
        <v-btn
            color="blue"
            variant="text"
            @click="resetMessageBox"
        >
          Loka
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script setup>
import {storeToRefs} from "pinia";
import {useUiStore} from "@/store/ui-store";

const { messageBox } = storeToRefs(useUiStore())

const { resetMessageBox } = useUiStore()

</script>

<style scoped>

</style>