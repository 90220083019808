// eslint-disable-next-line no-unused-vars
export async function getDirections(startLon, startLat, endLon, endLat) {
/*    let url = "https://api.mapbox.com/directions/v5/mapbox/driving/"
        + startLat + "," + startLon + ";" + endLat + "," + endLon + "?geometries=geojson&access_token=" + token*/

    let url = "https://directions.hlada.is/api/v1/directions?origin=" + startLat + "," + startLon + "&destination=" + endLat + "," + endLon

    const response = await fetch(url);


    const json = await response.json();

    console.log(json)

    const data = json.directions.matchings[0];
    const route = data.geometry.coordinates;
    const geojson = {
        type: 'Feature',
        properties: {},
        geometry: {
            type: 'LineString',
            coordinates: route
        }
    };

    return { geojson, legs: data.legs }
}

// create a function to do geo encoding for user input

//export async function